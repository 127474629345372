import { Reducer } from "redux";

import { FollowingPhysicianDetailsResponse } from "../models/Api/PhysicianDetails";
import { ReAuthPhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  GetFollowingPhysicianDetailsActionDispatchTypes,
  PostFollowingPhysicianDetailsActionDispatchTypes,
  PutFollowingPhysicianDetailsActionDispatchTypes,
} from "../constants/PhysicianDetails";
import { FollowingPhysicianDetailsForm } from "../models/PhysicianDetails";
import { mergeObjects } from "../utils";

const initialState: FollowingPhysicianDetailsResponse = {
  loading: true,
  response: FollowingPhysicianDetailsForm,
  error: null,
};

const FollowingPhysicianDetailsReducer: Reducer<
  FollowingPhysicianDetailsResponse,
  ReAuthPhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case GetFollowingPhysicianDetailsActionDispatchTypes.GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        error: {},
        loading: false,
      });
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        error: {},
        loading: false,
      });
    }
    case PutFollowingPhysicianDetailsActionDispatchTypes.PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
      });
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        error: {},
        loading: false,
      });
    }
    case PostFollowingPhysicianDetailsActionDispatchTypes.POST_FOLLOWING_PHYSICIAN_DETAILS_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
      });
    }

    case PostFollowingPhysicianDetailsActionDispatchTypes.FOLLOWING_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default FollowingPhysicianDetailsReducer;
