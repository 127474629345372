import { Reducer } from "redux";

import { UserDetailsResponse, UserDetailsRequest } from "../models/Api/User";
import { UserDetailsDispatchTypes } from "../models/DispatchTypes/User";
import { UserDetailsActionDispatchTypes } from "../constants/User";
import { mergeObjects } from "../utils";

export const initialState: UserDetailsResponse = {
  loading: true,
  response: {
    userId: -1,
    firstName: "",
    lastName: "",
    fullName: "",
    userName: "",
    email: "",
    userPhoto: "",
    designation: "",
    gender: "",
    phoneNo: "",
    userAddress: "",
    userStatus: "",
    lastLogin: "",
    isSuspended: false,
    userRoles: [],
    dateCreated: "",
    objectId: "",
    userCreated: null,
    userPhotoBlobId: 0,
  },
};

export const initialPostState: UserDetailsRequest = {
  loading: false,
  response: {
    userId: 0,
  },
};

const reset = () => ({
  response: initialState.response,
  loading: false,
  error: null,
});

const UserDetailsReducer: Reducer<
  UserDetailsResponse,
  UserDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case UserDetailsActionDispatchTypes.USER_DETAILS_INVOKE: {
      return mergeObjects(state, {
        response: null,
        loading: true,
        error: null,
      });
    }
    case UserDetailsActionDispatchTypes.USER_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: null,
      });
    }
    case UserDetailsActionDispatchTypes.USER_DETAILS_POST_INVOKE: {
      return mergeObjects(state, {
        response: null,
        loading: true,
        error: null,
      });
    }
    case UserDetailsActionDispatchTypes.USER_DETAILS_POST_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: null,
      });
    }
    case UserDetailsActionDispatchTypes.USER_DETAILS_POST_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
        response: null,
      });
    }
    case UserDetailsActionDispatchTypes.USER_DETAILS_RESET: {
      return reset();
    }
  }
  return state;
};

export default UserDetailsReducer;
