import { ServiceRequest } from "../models/Service";

export enum ServiceRequestActionDispatchType {
  SERVICE_REQUEST_INVOKE = "SERVICE_REQUEST_INVOKE",
  SERVICE_REQUEST_SUCCESS = "SERVICE_REQUEST_SUCCESS",
  SERVICE_REQUEST_RESET = "SERVICE_REQUEST_RESET",

  SERVICE_REQUEST_POST_ERROR = "SERVICE_REQUEST_POST_ERROR",
  SERVICE_REQUEST_POST_INVOKE = "SERVICE_REQUEST_POST_INVOKE",
  SERVICE_REQUEST_POST_SUCCESS = "SERVICE_REQUEST_POST_SUCCESS",
  SERVICE_REQUEST_POST_RESET = "SERVICE_REQUEST_POST_RESET",

  SERVICE_REQUEST_PUT_ERROR = "SERVICE_REQUEST_PUT_ERROR",
  SERVICE_REQUEST_PUT_INVOKE = "SERVICE_REQUEST_PUT_INVOKE",
  SERVICE_REQUEST_PUT_SUCCESS = "SERVICE_REQUEST_PUT_SUCCESS",
}

export const ServiceRequestInitialValues: ServiceRequest = {
  diseasesDto: [],
  psychologicalDiseasesDto: [],
  referralCareTypeDto: [],
  referralDisciplineDto: [],
  referralId: null,
  startOfCareDate: "",
  userCreated: null,
  currentEndDate: null,
  currentStartDate: null,
};

export enum ServiceProviderActionDispatchType {
  SERVICE_PROVIDER_DETAIL_INVOKE = "SERVICE_PROVIDER_DETAIL_INVOKE",
  SERVICE_PROVIDER_DETAIL_SUCCESS = "SERVICE_PROVIDER_DETAIL_SUCCESS",
  SERVICE_PROVIDER_DETAIL_RESET = "SERVICE_PROVIDER_DETAIL_RESET",

  SERVICE_PROVIDER_POST_SUCCESS = "SERVICE_PROVIDER_POST_SUCCESS",
  SERVICE_PROVIDER_POST_INVOKE = "SERVICE_PROVIDER_POST_INVOKE",
  SERVICE_PROVIDER_POST_ERROR = "SERVICE_PROVIDER_POST_ERROR",
  SERVICE_PROVIDER_POST_RESET = "SERVICE_PROVIDER_POST_RESET",

  SERVICE_PROVIDER_PUT_SUCCESS = "SERVICE_PROVIDER_PUT_SUCCESS",
  SERVICE_PROVIDER_PUT_INVOKE = "SERVICE_PROVIDER_PUT_INVOKE",
  SERVICE_PROVIDER_PUT_ERROR = "SERVICE_PROVIDER_PUT_ERROR",

  SERVICE_PROVIDER_LIST_INVOKE = "SERVICE_PROVIDER_LIST_INVOKE",
  SERVICE_PROVIDER_LIST_SUCCESS = "SERVICE_PROVIDER_LIST_SUCCESS",
  SERVICE_PROVIDER_LIST_RESET = "SERVICE_PROVIDER_LIST_RESET",
}
export enum ServiceProviderMasterActionDispatchType {
  SERVICE_PROVIDER_MASTER_SUCCESS = "SERVICE_PROVIDER_MASTER_SUCCESS",
  SERVICE_PROVIDER_MASTER_INVOKE = "SERVICE_PROVIDER_MASTER_INVOKE",
  SERVICE_PROVIDER_MASTER_RESET = "SERVICE_PROVIDER_MASTER_RESET",
}
export enum PatientStateActionDispatchType {
  PATIENT_STATE_SUCCESS = "PATIENT_STATE_SUCCESS",
  PATIENT_STATE_INVOKE = "PATIENT_STATE_INVOKE",
}

export enum CountyActionDispatchType {
  GET_COUNTY_SUCCESS = "GET_COUNTY_SUCCESS",
  GET_COUNTY_INVOKE = "GET_COUNTY_INVOKE"
}

export const ServiceProviderAddress = {
  streetName1: null,
  streetName2: null,
  providerCity: null,
  providerCounty: null,
  providerState: null,
  zipCode: null,
};

export const ServiceProviderDefaultValues = {
  referralId: 0,
  referralServiceProviderId: 0,
  serviceProviderId: null,
  providerName: "",
  providerNpi: "",
  providerId: 0,
  plexisOfficeId: 0,
  userCreated: null,
  ...ServiceProviderAddress,
};

export enum ReAuthServiceRequestActionDispatchType {
  RE_AUTH_SERVICE_REQUEST_SUCCESS = "RE_AUTH_SERVICE_REQUEST_SUCCESS",
  RE_AUTH_SERVICE_REQUEST_INVOKE = "RE_AUTH_SERVICE_REQUEST_INVOKE",
  RE_AUTH_SERVICE_REQUEST_ERROR = "RE_AUTH_SERVICE_REQUEST_ERROR",
  RE_AUTH_SERVICE_REQUEST_RESET = "RE_AUTH_SERVICE_REQUEST_RESET",

  RE_AUTH_SERVICE_REQUEST_POST_SUCCESS = "RE_AUTH_SERVICE_REQUEST_POST_SUCCESS",
  RE_AUTH_SERVICE_REQUEST_POST_INVOKE = "RE_AUTH_SERVICE_REQUEST_POST_INVOKE",

  RE_AUTH_SERVICE_REQUEST_PUT_SUCCESS = "RE_AUTH_SERVICE_REQUEST_PUT_SUCCESS",
  RE_AUTH_SERVICE_REQUEST_PUT_INVOKE = "RE_AUTH_SERVICE_REQUEST_PUT_INVOKE",
}

export enum AuthVisitTypes {
  CURRENT_AUTH_REQUESTED_VISITS = "currentAuthRequestedVisits",
  NEXT_AUTH_REQUESTED_VISITS = "nextAuthRequestedVisits",
}

export enum AllowedVisitCounts {
  MAX = 9999,
  MIN = 0,
}

export enum DisciplineTableHeader {
  SELECT = "Select",
  DISCIPLINES = "Disciplines",
  REQUESTED_NO_OF_VISITS = "Requested no. of visits",
  TOTAL = "TOTAL:",
}
