import { Type } from "../models/HealthPlan";
import { DEFAULT_PAGE, PER_PAGE } from "./FunctionalUtils";

export enum TrackingLabels {
  MODULE_RESPONSE = "Module Response",
  SUB_MODULE_RESPONSE = "Sub Module Response",
}

export enum referralPageIds {
  CREATE_NEW_REFERRAL = 1,
  REFERRAL_SOURCE_DETAILS = 2,
  INSURANCE_DETAILS = 3,
  PHYSICIAN_DETAILS = 4,
}

export const inputType = {
  patientImage: "patientImage",
  patientDocs: "patientDocs",
  profilePicture: "profilePicture",
  referralDocs: "referralDocs",
  userProfile: "userProfile",
};

export enum PatientPreferredLanguage {
  SPANISH = "SPANISH",
  ENGLISH = "ENGLISH",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum RequesterComponentInjectType {
  PRIOR_AUTH = "priorAuth",
  AUTH_DETAILS = "authDetails",
}

export enum VirtualizeAutocompleteInjectType {
  SERVICE_PROVIDER_NPI = "search-by-provider-name-npi",
}

export enum NppsSearchInjectedto {
  PATIENT_HEADER = "patient-header"
}
export const GENDER_DROPDOWN_OPTIONS: Type[] = [
  {
    label: "Male",
    value: "Male",
  },
  {
    label: "Female",
    value: "Female",
  },
  {
    label: "Unknown",
    value: "Unknown",
  },
];

export enum NotifyInternalDataType {
  PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT = "PRIOR_AUTH_REQUEST_EMERGENCY_CONTACT",
  PATIENT_HEADER_EMERGENCY_CONTACT = "PATIENT_HEADER_EMERGENCY_CONTACT",
  PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO = "PRIOR_AUTH_REQUEST_PRIMARY_PHYSICIAN_INFO",
}

export const DISCIPLINE_DETAILS = true;
export const URGENCY_STATUS_EXPEDITED_ID = 2;
export const REQUEST_FACILITY_NAME = "Health Plan Case Manager";

export const requiredFieldMsg = {
  EMERGENCY_CONTACT_NAME: "Contact name is required.",
};

export enum EmergencyContactTypes {
  ALTERNATE_CONTACT = "ALTERNATE_CONTACT",
  REQUEST_EMERGENCY_CONTACT = "REQUEST_EMERGENCY_CONTACT",
}

export const HeaderLabelDocumentReview = [
  { label: "DOC NO.", width: "7%", displayIcon: false, isDocNo: true },
  { label: "DOCUMENT NAME", width: "17%", displayIcon: false, isDocNo: false },
  { label: "DATE / TIME", width: "16%", displayIcon: false, isDocNo: false },
  { label: "SOURCE", width: "7%", displayIcon: false, isDocNo: false },
  { label: "DOC TYPE", width: "16%", displayIcon: false, isDocNo: false },
  { label: "CREATED BY", width: "15%", displayIcon: false, isDocNo: false },
  { label: "ACTIONS", width: "8%", displayIcon: false, isDocNo: false },
];

export enum LofHeaderNames {
  FUNCTIONAL_MOBILITY = "FunctionalMobility",
  ADLS = "ADLS",
  LIVING_STATUS = "livingStatus",
  AVAILABLE_ASSISTANCE = "AvailableAssistance",
  BED_MOBILITY = "BedMobility",
  TRANSFER_SIT_TO_STAND = "TransferSitToStand",
  TOILETING_HYGIENE = "ToiletingHygiene",
  TRANSFER_TOILET = "TransferToilet",
  TRANSFER_TUB_SHOWER = "TransferTubShower",
  BATHING_SHOWERING = "BathingShowering",
  LOWER_BODY_DRESSING = "LowerBodyDressing",
  UPPER_BODY_DRESSING = "UpperBodyDressing",
  GROOMING = "Grooming",
  AMBULATION = "Ambulation",
  INPATIENT = "Inpatient",
  CURRENT = "Current",
  UPPER_EXTREMITIES = "UpperExtremities",
  LOWER_EXTREMITIES = "LowerExtremities",
  CURRENT_DEVICES = "CurrentDevices",
  PRIOR_DEVICES = "PriorDevices",
  RECOMMENDED_DEVICES = "RecommendedDevices",
  OTHER_DEVICES = "OtherDevices",
  ESTABLISHED = "Established",
  PLAN_TO_ESTABLISH_HEP = "PlanToEstablishHep",
}

export enum LOFLabelNames {
  PLOF = "PLOF",
  CLOF = "CLOF",
  AMBULATION_DISTANCE = "Ambulation Distance",
  WEIGHT_BEARING_STATUS = "Weight Bearing Status",
  ASSISTIVE_DEVICES = "Assistive devices",
  HEP = "HEP",
  LIVING_STATUS_LABEL = "Living status",
  AVAILABLE_ASSISTANCE_LABEL = "Available assistance",
  PRIOR_ASSISTIVE_DEVICES = "Prior Assistive Devices",
  INPT_CURRENT_LOF = "Inpt/Current LOF",
}

export const DefaultLOF = [
  {
    key: LOFLabelNames.PLOF,
    value: LOFLabelNames.PLOF,
    LOF: [
      {
        key: LofHeaderNames.FUNCTIONAL_MOBILITY,
        label: "Functional Mobility",
        keyPath: "PLOF.Functional Mobility",
        identityPath: "PLOF LOA",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.ADLS,
        label: "ADLS",
        keyPath: "PLOF.ADLs",
        identityPath: "PLOF LOA",
        borderRequired: true,
      },
    ],
  },
  {
    key: LOFLabelNames.CLOF,
    value: LOFLabelNames.CLOF,
    LOF: [
      {
        key: LofHeaderNames.LIVING_STATUS,
        label: "Living Status",
        keyPath: "",
        identityPath: "Living status",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.AVAILABLE_ASSISTANCE,
        label: "Available Assistance",
        keyPath: "",
        identityPath: "Available assistance",
        borderRequired: true,
      },
      {
        key: LofHeaderNames.BED_MOBILITY,
        label: "Bed Mobility",
        keyPath: "Inpt/Current LOF.Bed Mobility",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.TRANSFER_SIT_TO_STAND,
        label: "Transfer: Sit to Stand",
        keyPath: "Inpt/Current LOF.Transfer:sittostand",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.TOILETING_HYGIENE,
        label: "Toileting/Hygiene",
        keyPath: "Inpt/Current LOF.Toileting/Hygiene",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.TRANSFER_TOILET,
        label: "Transfer: Toilet",
        keyPath: "Inpt/Current LOF.Transfer:toilet",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.TRANSFER_TUB_SHOWER,
        label: "Transfer: Tub/Shower",
        keyPath: "Inpt/Current LOF.Transfer:tub/shower",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.BATHING_SHOWERING,
        label: "Bathing/Showering",
        keyPath: "Inpt/Current LOF.Bathing/Showering",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.LOWER_BODY_DRESSING,
        label: "Lower Body Dressing",
        keyPath: "Inpt/Current LOF.Lower Body Dressing",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.UPPER_BODY_DRESSING,
        label: "Upper Body Dressing",
        keyPath: "Inpt/Current LOF.Upper Body Dressing",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.GROOMING,
        label: "Grooming",
        keyPath: "Inpt/Current LOF.Grooming",
        identityPath: "CLOF",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.AMBULATION,
        label: "Ambulation",
        keyPath: "Inpt/Current LOF.Row 5",
        identityPath: "CLOF",
        borderRequired: true,
      },
    ],
  },
  {
    key: LOFLabelNames.AMBULATION_DISTANCE,
    value: LOFLabelNames.AMBULATION_DISTANCE,
    LOF: [
      {
        key: LofHeaderNames.INPATIENT,
        label: "Inpatient",
        keyPath: "Ambulation.Inpt",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.CURRENT,
        label: "Current",
        keyPath: "Ambulation.Current",
        identityPath: "",
        borderRequired: true,
      },
    ],
  },
  {
    key: LOFLabelNames.WEIGHT_BEARING_STATUS,
    value: LOFLabelNames.WEIGHT_BEARING_STATUS,
    LOF: [
      {
        key: LofHeaderNames.UPPER_EXTREMITIES,
        label: "Upper Extremities",
        keyPath: "Weight Bearing Status.UpperExtremities.LOA",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.LOWER_EXTREMITIES,
        label: "Lower Extremities",
        keyPath: "Weight Bearing Status.LowerExtremities.LOA",
        identityPath: "",
        borderRequired: true,
      },
    ],
  },
  {
    key: LOFLabelNames.ASSISTIVE_DEVICES,
    value: LOFLabelNames.ASSISTIVE_DEVICES,
    LOF: [
      {
        key: LofHeaderNames.PRIOR_DEVICES,
        label: "Prior devices",
        keyPath: "Prior Assistive Devices",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.CURRENT_DEVICES,
        label: "Current devices",
        keyPath: "current Assistive Devices",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.RECOMMENDED_DEVICES,
        label: "Recommended devices",
        keyPath: "recommended Assistive Devices",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.OTHER_DEVICES,
        label: "Other devices",
        keyPath: "Other devices",
        identityPath: "",
        borderRequired: true,
      },
    ],
  },
  {
    key: LOFLabelNames.HEP,
    value: LOFLabelNames.HEP,
    LOF: [
      {
        key: LofHeaderNames.ESTABLISHED,
        label: "Established",
        keyPath: "HEP established",
        identityPath: "",
        borderRequired: false,
      },
      {
        key: LofHeaderNames.PLAN_TO_ESTABLISH_HEP,
        label: "Plan to Establish HEP",
        keyPath: "Plan to Establish HEP",
        identityPath: "",
        borderRequired: false,
      },
    ],
  },
];

export const DefaultPaginationData = {
  pageNo: DEFAULT_PAGE,
  pageSize: PER_PAGE,
};
