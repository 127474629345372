import { useEffect, useRef, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import empty, { debounce, isEmpty } from "lodash";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useParams } from "react-router";

import DatePicker from "../../../../../components/formComponents/DatePicker";
import {
  CareType,
  Discipline,
  DisciplineList,
  DisciplineSearch,
  PsychologicalHistory,
} from "../../../../../models/Service";
import {
  colors,
  others as otherColor,
} from "../../../../../styles/colors";

import fontWeight from "../../../../../styles/mui/fontWeight";
import {
  AllCheckboxPadding,
  SearchCareTypeBox,
  CareTypeBoxPaddingStyle,
  CareTypeDividerStyle,
  CareTypeBox,
  CareTypeLefBoxtStyle,
  CareTypePaper,
  CareTypeRightBoxtStyle,
  DateStyle,
  DesciplinesTypeGrid,
  DidciplinesDividerStyle,
  DividerStyle,
  GrideItem,
  InputBaseStyle,
  OuterGridContainer,
  PaddingAll,
  PaddingStyle,
  PsycologicalTitle,
  StartCareDate,
  Title,
  Casing,
  TopPaddingStyle,
  VerticalDivider,
  GridScrolling,
  PsychologicalLeftPaddingStyle,
  PastMedicalHistoryCheckbox,
  NonCoveredCheckbox,
  BottomPadding,
  Asterisk,
} from "../styles/ServiceRequest";
import {
  CareTypeResponse,
  DisciplinesResponse,
  PastMedicalHistoryResponse,
  PsychologicalHistoryResponse,
} from "../../../../../models/Api/Master";
import {
  PostServiceRequestResponse,
  ServiceRequestResponse,
} from "../../../../../models/Api/Service";
import {
  getValue,
  is21OrTurning21Soon,
  length,
} from "../../../../../utils";
import { rules } from "../../../../../utils/validation/Validation";
import { ReferralOverviewDetail } from "../../../../../models/Patient";
import { displayFlex } from "../../../../../styles/mui/styles/display";
import { NO_RECORD_FOUND } from "../../../../../constants/ToastMessage";
import { UserDetailsPayload } from "../../../../../models/User";
import { DisciplinesActionDispatchTypes } from "../../../../../constants/Master";
import { ServiceRequestType } from "../../../../../constants/Master";
import { dateFormat, DateFormats } from "../../../../../constants/Date";
import { PatientInformationViewResponse } from "../../../../../models/Api/PatientInformation";
import { formatDate } from "../../../../../utils/DateTime";
export interface PropsDispatch {
  getDisciplines: (payload: DisciplineSearch) => void;
  getCareType: () => void;
  getPastMedicalHistory: () => void;
  getPsychologicalHistory: () => void;
  resetStates: (actionType: string[]) => void;
  getServiceRequest: (referralId: number, startOfCareDate?: string) => void;
}
export interface PropsState {
  setValue: (id: any, value: any, boolean: any) => void;
  control: any;
  values: any;
  disciplinesState: DisciplinesResponse;
  careTypeData: CareTypeResponse;
  pastMedicalHistoryData: PastMedicalHistoryResponse;
  psychologicalHistoryData: PsychologicalHistoryResponse;
  serviceRequestInfo: ServiceRequestResponse;
  patientDetail: ReferralOverviewDetail;
  clearErrors: (name: string) => void;
  user: UserDetailsPayload;
  disableSections: boolean;
  serviceRequestUpdateData: PostServiceRequestResponse;
  authTypeId: number;
  patientHeaderData: PatientInformationViewResponse;
  formState : any;
}
type AllProps = PropsState & PropsDispatch;

const ServiceRequestUI: React.FC<AllProps> = ({
  disciplinesState,
  setValue,
  values,
  control,
  careTypeData,
  psychologicalHistoryData,
  serviceRequestInfo,
  patientDetail,
  getDisciplines,
  getCareType,
  getPsychologicalHistory,
  clearErrors,
  user,
  disableSections,
  serviceRequestUpdateData,
  resetStates,
  authTypeId,
  patientHeaderData,
  getServiceRequest,
  formState,
}: AllProps) => {
  const loggedInUser = getValue(user, "fullName", "");
  const [disciplinesRecord, setDisciplinesRecord] = useState(
    disciplinesState.response
  );

  const [searchCareType, setSearchCareType] = useState(careTypeData.response);

  const [psychologicalHistoryRecord, setPsychologicalHistoryRecord] = useState(
    psychologicalHistoryData.response
  );

  const [disciplineList, setDisciplineList] = useState<any[]>();
  const [careTypeList, setCareTypeList] = useState<any[]>();
  const [psychologicalHistoryList, setPsychologicalHistoryList] =
    useState<any[]>();
  const [isPatientGoingToBe21, setIsPatientGoingToBe21] = useState(false);
  const [isSt, setIsSt] = useState(false);
  const [defaultSelectedDiscipline, setDefaultSelectedDiscipline] =
    useState<any>();

  const { referralId } = useParams();
  const { response: patientHeaderResponse } = patientHeaderData;
  const hasExecutedOnce = useRef(false);
  useEffect(() => {
    if (
      disciplinesRecord &&
      disciplinesRecord.some(
        (data: Discipline) => data.azCompeleteCheck === true
      )
    ) {
      if (
        is21OrTurning21Soon(
          patientHeaderResponse.memberInfo.dateOfBirth,
          getValue(serviceRequestInfo, "response.currentStartDate"),
          getValue(serviceRequestInfo, "response.currentEndDate")
        )
      ) {
        setIsPatientGoingToBe21(true);
      } else {
        setIsPatientGoingToBe21(false);
      }
    }
  }, [serviceRequestInfo.response , disciplinesRecord]);

  useEffect(() => {
    if (
      "undefined" !== typeof values.StartOfCareDate &&
      dateFormat == values.StartOfCareDate
    ) {
      setValue("StartOfCareDate", null, true);
    }

  }, [values && values.StartOfCareDate]);

  const handleSearch = (event: any) => {
    if (empty.isEmpty(event.target.value)) {
      setSearchCareType(careTypeData.response);
    } else {
      const results = careTypeData.response.filter((careType) => {
        if (empty.isEmpty(event.target.value)) return careType;
        return careType.careTypeName
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      const updatedArr = [...results];
      setSearchCareType(updatedArr);
    }
  };

  const handleDisciplinesList = (e: any) => {
    const disciplines = disciplineList ? disciplineList : [];

    const disciplinesUnderIds =
      disciplines &&
      length(disciplines) &&
      disciplines.map((disc) => disc.disciplineId);

    const selectedDiscipline: Discipline = disciplinesRecord.filter(
      (discipline: Discipline) =>
        discipline.disciplineId === Number(e.target.value)
    )[0];
    
    if (
      disciplinesUnderIds &&
      disciplinesUnderIds.includes(Number(e.target.value))
    ) {
      if (selectedDiscipline.azCompeleteCheck) {
        setIsSt(false);
      }
      const newDisciplines =
        disciplines && length(disciplines)
          ? disciplines.filter((item) => {
              return item.disciplineId != e.target.value;
            })
          : [];

      const newDisciplinesList =
        newDisciplines && length(newDisciplines)
          ? newDisciplines.map((item, index) => ({
              disciplineId: item.disciplineId,
              serviceNumber: index + 1,
              createdUserFullName: loggedInUser,
            }))
          : [];

      setDisciplineList(newDisciplinesList);
      return;
    }
    if (selectedDiscipline.azCompeleteCheck) {
      setIsSt(true);
    }
    const discipline: DisciplineList = {
      disciplineId: Number(e.target.value),
      serviceNumber:
        disciplineList && length(disciplineList)
          ? disciplineList.length + 1
          : 1,
      createdUserFullName: loggedInUser,
    };
    const newDisciplinesList: DisciplineList[] =
      disciplines && length(disciplines)
        ? disciplines.map((item, index) => ({
            disciplineId: item.disciplineId,
            serviceNumber: index + 1,
            createdUserFullName: loggedInUser,
          }))
        : [];
    const newArrWithAddedDiscipline = [...newDisciplinesList, discipline];
    setDisciplineList(newArrWithAddedDiscipline);
  };

  const handleCareTypeList = (e: any) => {
    const careTypes = careTypeList ? careTypeList : [];

    const careTypeUnderIds =
      careTypes &&
      length(careTypes) &&
      careTypes.map((careType) => careType.careTypeId);

    if (careTypeUnderIds && careTypeUnderIds.includes(Number(e.target.value))) {
      const newCareType =
        careTypes && length(careTypes)
          ? careTypes.filter((item) => {
              return item.careTypeId != e.target.value;
            })
          : [];
      setCareTypeList(newCareType);
      return;
    }
    const careType = { careTypeId: Number(e.target.value) };
    const newArrWithAddedCareTypeId = [...careTypes, careType];
    setCareTypeList(newArrWithAddedCareTypeId);
  };

  const filterPsychologicalDiseasName = (val: any) => {
    const diseases = psychologicalHistoryData.response.filter(
      (obj: PsychologicalHistory) => obj.psychologicalDiseaseId === val
    );
    return diseases && diseases.length > 0
      ? diseases[0].psychologicalDiseaseName
      : "";
  };

  const handlePsychologicalHistoryList = (e: any) => {
    const psychologicalDiseases = psychologicalHistoryList
      ? psychologicalHistoryList
      : [];
    const psychologicalDiseasesUnderIds =
      psychologicalDiseases &&
      length(psychologicalDiseases) &&
      psychologicalDiseases.map(
        (psychologicaldis) => psychologicaldis.psychologicalDiseaseId
      );
    if (
      psychologicalDiseasesUnderIds &&
      psychologicalDiseasesUnderIds.includes(Number(e.target.value))
    ) {
      const newPsychologicalHistory =
        psychologicalDiseases && length(psychologicalDiseases)
          ? psychologicalDiseases.filter(
              (item) => item.psychologicalDiseaseId != e.target.value
            )
          : [];
      setPsychologicalHistoryList(newPsychologicalHistory);
      return;
    }
    const psychologicalDiseas = {
      psychologicalDiseaseId: Number(e.target.value),
      psychologicalDiseaseName: filterPsychologicalDiseasName(
        Number(e.target.value)
      ),
    };

    const newArrWithAddedPsychologicalDiseas = [
      ...psychologicalDiseases,
      psychologicalDiseas,
    ];
    setPsychologicalHistoryList(newArrWithAddedPsychologicalDiseas);
  };

  useEffect(() => {
    const payload: DisciplineSearch = {
      id: Number(referralId),
      type: ServiceRequestType.AUTH_ID,
      requiredTbd: false,
      authorizationTypeId: authTypeId,
      patientEligibilityId: getValue(
        patientHeaderResponse,
        "patientEligibilityId"
      ),
    };
    if (
      authTypeId > 0 &&
      getValue(patientHeaderResponse, "patientEligibilityId") > 0 &&
      !hasExecutedOnce.current
    ) {
      getDisciplines(payload);
      getCareType();
      getPsychologicalHistory();
      hasExecutedOnce.current = true;
    }
    return () => {
      resetStates([DisciplinesActionDispatchTypes.DISCIPLINES_RESET]);
    };
  }, [authTypeId, patientHeaderResponse]);

  useEffect(() => {
    if (length(serviceRequestUpdateData.response.referralDisciplineDto)) {
      updatedDisciplineList(1);
    }
  }, [serviceRequestUpdateData.response]);

  const updatedDisciplineList = (type: number) => {
    let referralDisciplineDto = [];
    let dtoResponse = null;
    if (type === 1) {
      dtoResponse = getValue(
        serviceRequestUpdateData,
        "response.referralDisciplineDto",
        []
      );
    } else {
      dtoResponse = getValue(
        serviceRequestInfo,
        "response.referralDisciplineDto",
        []
      );
    }
    referralDisciplineDto = isEmpty(dtoResponse) ? [] : dtoResponse;
    setValue("referralDisciplineDto", referralDisciplineDto, true);
    setDisciplineList(referralDisciplineDto);
    setDefaultSelectedDiscipline(referralDisciplineDto);
  };

  useEffect(() => {
    setDisciplinesRecord(disciplinesState.response);
    setSearchCareType(careTypeData.response);
    setPsychologicalHistoryRecord(psychologicalHistoryData.response);
    updatedDisciplineList(0);
    setCareTypeList(
      serviceRequestInfo.response &&
        serviceRequestInfo.response.referralCareTypeDto &&
        serviceRequestInfo.response.referralCareTypeDto.length > 0
        ? serviceRequestInfo.response.referralCareTypeDto
        : []
    );
    setPsychologicalHistoryList(
      serviceRequestInfo.response &&
        serviceRequestInfo.response.psychologicalDiseasesDto &&
        serviceRequestInfo.response.psychologicalDiseasesDto.length > 0
        ? serviceRequestInfo.response.psychologicalDiseasesDto
        : []
    );
  }, [
    disciplinesState.response,
    careTypeData.response,
    psychologicalHistoryData.response,
    serviceRequestInfo.response,
  ]);

  useEffect(() => {
    if (disciplineList && length(disciplineList)) {
      clearErrors("referralDisciplineDto");
    }
    setValue("referralDisciplineDto", disciplineList, true);
    setValue("referralCareTypeDto", careTypeList, true);
    setValue("psychologicalDiseasesDto", psychologicalHistoryList, true);
  }, [disciplineList, careTypeList, psychologicalHistoryList]);

  const maximumDate = dayjs().add(60, "day");

  const handleDateChange = debounce((e: Date) => {
    const newDate = formatDate(e, DateFormats.YYYY_MM_DD);
    const selectedDisciplineList = disciplinesRecord ? disciplinesRecord : [];
    if (
      Object.prototype.hasOwnProperty.call(
        formState.errors,
        "startOfCareDate"
      ) === false &&
      selectedDisciplineList.some(
        (discipline: Discipline) => discipline.azCompeleteCheck
      )
    ) {
      getServiceRequest(Number(referralId), newDate);
    }
    setValue("referralDisciplineDto", [], true);
    setDisciplineList([]);
    setIsSt(false);
  }, 500);

  return (
    <>
      <Grid container sx={OuterGridContainer}>
        <Grid item xs={12}>
          <Grid container sx={GrideItem}>
            <Grid item xs={12}>
              <Box sx={PaddingStyle}>
                <Typography
                  variant="h6"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.black[3]}
                  sx={Casing}
                >
                  Ordered disciplines & skills
                </Typography>
                <Typography
                  mt={"0.5rem"}
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.black[5]}
                >
                  Please choose the Disciplines and skills applicable for &nbsp;
                  {patientDetail && patientDetail.memberName
                    ? patientDetail.memberName
                    : ""}
                </Typography>
              </Box>
              <Divider sx={DividerStyle} />
              <Box sx={DateStyle}>
                <Typography
                  variant="subtitle2"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.fonts[4]}
                  sx={StartCareDate}
                >
                  Requested Start of Care Date:
                </Typography>
                <Typography
                  variant="subtitle1"
                  color={colors.red[90]}
                  fontWeight={fontWeight.Weight[4]}
                  pr={2}
                  sx={Asterisk}
                >
                  *
                </Typography>
                <FormControl variant="standard">
                  <DatePicker
                    name="startOfCareDate"
                    control={control}
                    label={""}
                    fieldrequired={"required"}
                    helper={rules.referralSourceDischargeDate}
                    allowFutureDate={true}
                    maximumdate={maximumDate}
                    minimumDate={
                      disableSections
                        ? dayjs(getValue(values, "startOfCareDate"))
                        : null
                    }
                    disabled={disableSections}
                    onChangeHandler={handleDateChange}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} sx={PaddingStyle}>
              <Box sx={displayFlex}>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[4]}
                  color={colors.black[3]}
                >
                  CHOOSE DISCIPLINES:
                </Typography>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[4]}
                  color={colors.red[100]}
                  ml={"0.5rem"}
                >
                  *
                </Typography>
              </Box>
              <DesciplinesTypeGrid>
                <Controller
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <FormGroup
                        {...field}
                        onChange={handleDisciplinesList}
                        sx={NonCoveredCheckbox}
                      >
                        {disciplinesRecord &&
                          length(disciplinesRecord) &&
                          disciplinesRecord.map((item: Discipline) => {
                            const disciplieIds =
                              disciplineList &&
                              length(disciplineList) &&
                              disciplineList.map(
                                (dis: Discipline) => dis.disciplineId
                              );
                            return (
                              <FormControlLabel
                                key={item.disciplineId}
                                control={
                                  <Checkbox
                                    value={item.disciplineId}
                                    data-testid={`discipline-${item.disciplineId}`}
                                    size="small"
                                    disabled={
                                      disableSections &&
                                      !isEmpty(defaultSelectedDiscipline) &&
                                      defaultSelectedDiscipline.some(
                                        (v: any) =>
                                          v.disciplineId === item.disciplineId
                                      )
                                    }
                                    checked={
                                      disciplieIds &&
                                      disciplieIds.includes(item.disciplineId)
                                        ? true
                                        : false
                                    }
                                  />
                                }
                                label={
                                  <Typography
                                    variant="subtitle2"
                                    color={otherColor.otherColors[63]}
                                    fontWeight={fontWeight.Weight[5]}
                                    mr={"3rem"}
                                  >
                                    {item.disciplineName}
                                  </Typography>
                                }
                              />
                            );
                          })}
                      </FormGroup>
                      <FormHelperText>
                        {error ? error.message : null}
                      </FormHelperText>
                      {getValue(patientHeaderResponse, "isMedicaid") &&
                        isPatientGoingToBe21 &&
                        isSt && (
                          <Box>
                            <Typography
                              variant="body1"
                              color={colors.red[90]}
                              fontWeight={fontWeight.Weight[3]}
                            >
                              AZ Complete Medicaid members do not have speech
                              therapy coverage after their 21st birthday. This
                              request has dates in which the member will turn 21
                              years old,
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  )}
                  name={`referralDisciplineDto`}
                  control={control}
                  rules={rules.discipline}
                />
              </DesciplinesTypeGrid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={DidciplinesDividerStyle} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={TopPaddingStyle}>
            <Grid item xs={5.3} sx={CareTypeLefBoxtStyle}>
              <Box>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.black[3]}
                  sx={Title}
                >
                  CHOOSE CARE TYPES APPLICABLE:
                </Typography>
              </Box>
              <Box sx={CareTypeBoxPaddingStyle}>
                <Typography
                  variant="subtitle1"
                  fontWeight={fontWeight.Weight[5]}
                  color={colors.black[3]}
                  sx={Title}
                >
                  CARE TYPES:
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight={fontWeight.Weight[3]}
                  color={colors.fonts[11]}
                >
                  Please choose the care types fron the below list:
                </Typography>
              </Box>
              <Box sx={CareTypeBox}>
                <Paper component="form" sx={CareTypePaper}>
                  <IconButton sx={PaddingAll} aria-label="menu">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    sx={InputBaseStyle}
                    disabled={disableSections}
                    placeholder="Search by name or any keyword"
                    onChange={handleSearch}
                    inputProps={{
                      maxLength: 50,
                    }}
                    data-testid="prior-auth-search-care-type"
                  />
                </Paper>
                <SearchCareTypeBox sx={GridScrolling}>
                  <FormGroup onChange={handleCareTypeList}>
                    {searchCareType && !length(searchCareType) && (
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            fontWeight={fontWeight.Weight[4]}
                            color={colors.fonts[4]}
                            pt={"0.2rem"}
                          >
                            {NO_RECORD_FOUND}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {searchCareType &&
                      length(searchCareType) &&
                      searchCareType.map((item: CareType) => {
                        const careTypeIds =
                          careTypeList &&
                          length(careTypeList) &&
                          careTypeList.map(
                            (careType: CareType) => careType.careTypeId
                          );
                        return (
                          <Box key={item.careTypeId}>
                            <FormControlLabel
                              key={item.careTypeId}
                              control={
                                <Checkbox
                                  data-testid={`care-type-${item.careTypeId}`}
                                  value={item.careTypeId}
                                  size="small"
                                  disabled={disableSections}
                                  checked={
                                    careTypeIds &&
                                    careTypeIds.includes(item.careTypeId)
                                      ? true
                                      : false
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle2"
                                  color={otherColor.otherColors[63]}
                                  fontWeight={fontWeight.Weight[5]}
                                  ml={"1rem"}
                                >
                                  {item.careTypeName}
                                </Typography>
                              }
                            />
                            <Divider sx={CareTypeDividerStyle} />
                          </Box>
                        );
                      })}
                  </FormGroup>
                </SearchCareTypeBox>
              </Box>
            </Grid>
            <Grid item xs={0.2}>
              <Divider
                orientation="vertical"
                variant="middle"
                textAlign="center"
                sx={VerticalDivider}
              />
            </Grid>
            <Grid item xs={6.5} sx={CareTypeRightBoxtStyle}>
              <Box sx={PaddingStyle}>
                <Typography
                  variant="h6"
                  fontWeight={fontWeight.Weight[6]}
                  color={colors.black[3]}
                  sx={Title}
                >
                  MEDICAL HISTORY:
                </Typography>
              </Box>
              <Grid container sx={PsychologicalLeftPaddingStyle}>
                <Grid item xs={12}>
                  <Box sx={BottomPadding}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={fontWeight.Weight[5]}
                      color={colors.fonts[4]}
                      sx={PsycologicalTitle}
                    >
                      Psychological History:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={AllCheckboxPadding}>
                  <Grid container>
                    <FormGroup
                      onChange={handlePsychologicalHistoryList}
                      sx={PastMedicalHistoryCheckbox}
                    >
                      {psychologicalHistoryRecord &&
                        length(psychologicalHistoryRecord) &&
                        psychologicalHistoryRecord.map(
                          (item: PsychologicalHistory) => {
                            const psychologicalDiseasesId =
                              psychologicalHistoryList &&
                              length(psychologicalHistoryList) &&
                              psychologicalHistoryList.map(
                                (dis) => dis.psychologicalDiseaseId
                              );
                            return (
                              <Grid
                                item
                                xs={3}
                                key={item.psychologicalDiseaseId}
                              >
                                <FormControlLabel
                                  key={item.psychologicalDiseaseId}
                                  control={
                                    <Checkbox
                                      data-testid={`psychological-disease-${item.psychologicalDiseaseId}`}
                                      value={item.psychologicalDiseaseId}
                                      size="small"
                                      disabled={disableSections}
                                      checked={
                                        psychologicalDiseasesId &&
                                        psychologicalDiseasesId.includes(
                                          item.psychologicalDiseaseId
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="subtitle2"
                                      color={colors.fonts[4]}
                                      fontWeight={fontWeight.Weight[5]}
                                    >
                                      {item.psychologicalDiseaseName}
                                    </Typography>
                                  }
                                />
                              </Grid>
                            );
                          }
                        )}
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ServiceRequestUI;
