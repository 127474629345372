import { Reducer } from "redux";

import { PhysicianDetailsResponse } from "../models/Api/PhysicianDetails";
import { ReferralIntakePhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  GetPhysicianDetailsActionDispatchTypes,
  PhysicianTypeID,
  PostPhysicianDetailsActionDispatchTypes,
  PutPhysicianDetailsActionDispatchTypes,
} from "../constants/PhysicianDetails";
import { mergeObjects } from "../utils";

const initialState: PhysicianDetailsResponse = {
  loading: true,
  response: {
    primaryPhysician: {
      referralId: 0,
      referralPhysicianDetailId: 0,
      physicianId: -1,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName: "",
      county: "",
      city: "",
      physicianState: "",
      zipCode: "",
      userCreated: null,
      physicianTypeId: PhysicianTypeID.PRIMARY,
    },
    orderingPhysician: {
      referralId: 0,
      physicianId: -1,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName: "",
      county: "",
      city: "",
      state: "",
      zipCode: "",
      physicianState: "",
      physicianTypeId: PhysicianTypeID.ORDERING,
      physicianSameAsPrimary: false,
    },
  },
  error: null,
};

const ReferralIntakePhysicianDetailsReducer: Reducer<
  PhysicianDetailsResponse,
  ReferralIntakePhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: {},
      });
    }

    case GetPhysicianDetailsActionDispatchTypes.GET_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: {},
      });
    }
    case PutPhysicianDetailsActionDispatchTypes.PUT_PHYSICIAN_DETAILS_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
      });
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: {},
      });
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
      });
    }
    case PostPhysicianDetailsActionDispatchTypes.POST_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ReferralIntakePhysicianDetailsReducer;
