import { Reducer } from "redux";

import { UpdateRoleDetailsResponse } from "../models/Api/Roles";
import { RoleDetailsDispatchTypes } from "../models/DispatchTypes/Roles";
import { RoleDetailsActionDispatchTypes } from "../constants/Roles";
import { mergeObjects } from "../utils";

export const initialState: UpdateRoleDetailsResponse = {
  loading: false,
  response: {
    roleId: -1,
    roleName: "",
    roleDescription: "",
    roleModule: [
      {
        moduleId: -1,
        moduleName: "",
        roleId: -1,
        roleSubModule: [
          {
            moduleId: -1,
            roleId: -1,
            subModuleId: -1,
            subModuleName: "",
            accesses: [],
          },
        ],
      },
    ],
    loggedInUser: "",
  },
  error: null,
};

const reset = () => ({
  response: null,
  loading: false,
  error: null,
});

const UpdateRoleDetailsReducer: Reducer<
  UpdateRoleDetailsResponse,
  RoleDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_INVOKE: {
      return mergeObjects(state, {
        response: null,
        loading: true,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_ERROR: {
      return mergeObjects(state, {
        error: action.response,
        loading: false,
        response: null,
      });
    }
    case RoleDetailsActionDispatchTypes.UPDATE_ROLE_RESET: {
      return reset();
    }
  }
  return state;
};

export default UpdateRoleDetailsReducer;
