import { Reducer } from "redux";

import { RoleDetailsResponse } from "../models/Api/Roles";
import { RoleDetailsDispatchTypes } from "../models/DispatchTypes/Roles";
import { RoleDetailsActionDispatchTypes } from "../constants/Roles";
import { mergeObjects } from "../utils";

export const initialState: RoleDetailsResponse = {
  loading: false,
  response: {
    roleId: -1,
    roleName: "",
    roleDescription: "",
    roleModule: [
      {
        moduleId: -1,
        moduleName: "",
        roleId: -1,
        roleSubModule: [
          {
            moduleId: -1,
            roleId: -1,
            subModuleId: -1,
            subModuleName: "",
            accesses: [],
          },
        ],
      },
    ],
    loggedInUser: "",
  },
  error: null,
};

const reset = () => ({
  response: null,
  loading: false,
  error: null,
});

const RoleDetailsReducer: Reducer<
  RoleDetailsResponse,
  RoleDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RoleDetailsActionDispatchTypes.ROLE_DETAILS_INVOKE: {
      return mergeObjects(state, {
        loading: true,
        response: null,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.ROLE_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        loading: false,
        response: action.response,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.POST_ROLE_INVOKE: {
      return mergeObjects(state, {
        loading: true,
        response: null,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.POST_ROLE_SUCCESS: {
      return mergeObjects(state, {
        loading: false,
        response: action.response,
        error: null,
      });
    }
    case RoleDetailsActionDispatchTypes.POST_ROLE_ERROR: {
      return mergeObjects(state, {
        loading: false,
        error: action.response,
      });
    }
    case RoleDetailsActionDispatchTypes.ROLE_DETAILS_RESET: {
      return reset();
    }
  }
  return state;
};

export default RoleDetailsReducer;
