import { GetQueryString } from "../utils";
import { RationaleTemplatePayload } from "../models/Master";
import API from "./api";
import { DisciplineSearch } from "../models/Service";

export const GetMasterAccess = (configUrl: string) =>
  API.get(`${configUrl}api/roles/accesslist`);

export const GetMasterCallOutcome = (configUrl: string) =>
  API.get(`${configUrl}api/master/CallOutcomes`);

export const GetHealthProgram = (configUrl: string) =>
  API.get(`${configUrl}api/master/healthprogram`);

export const GetCareCoordinationProgram = (configUrl: string) =>
  API.get(`${configUrl}api/master/carecoordination`);

export const GetNonCoveredService = (configUrl: string) =>
  API.get(`${configUrl}api/master/NonCoveredServices`);

export const GetOrderingSource = (configUrl: string) =>
  API.get(`${configUrl}api/master/OrderingSource`);

export const GetOrderAcceptor = (configUrl: string) =>
  API.get(`${configUrl}api/master/OrderAcceptor`);

export const GetDisciplines = (configUrl: string, payload: DisciplineSearch) =>
  API.get(`${configUrl}api/master/Disciplines?${GetQueryString(payload)}`);

export const GetCareType = (configUrl: string) =>
  API.get(`${configUrl}api/master/CareTypes`);

export const GetPastMedicalHistory = (configUrl: string) =>
  API.get(`${configUrl}api/master/Diseases`);

export const GetPsychologicalHistory = (configUrl: string) =>
  API.get(`${configUrl}api/master/PsychologicalDiseases`);

export const IcdCodeTypeData = (configUrl: string) =>
  API.get(`${configUrl}api/master/DiagnosisType`);

export const GetDocumentType = (configUrl: string) =>
  API.get(`${configUrl}api/master/DocumentType?excludeLetter=${true}`);

export const GetReferralServiceStatusType = (
  configUrl: string,
  requiredIncompleteOption: boolean
) =>
  API.get(
    `${configUrl}api/master/ReferralServiceStatus?requiredIncomplete=${requiredIncompleteOption}`
  );

export const GetServiceStatusReasonMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/StatusReasons`);

export const GetDisciplineType = (configUrl: string) =>
  API.get(`${configUrl}api/master/ServiceType`);

export const GetServiceStatusReason = (configUrl: string, serviceId: number) =>
  API.get(`${configUrl}api/master/StatusReason/${serviceId}`);

export const GetDocumentSource = (configUrl: string) =>
  API.get(`${configUrl}api/master/DocumentReview`);

export const GetDocumentCreatedBy = (configUrl: string, searchText: string) =>
  API.get(`${configUrl}api/master/UserDetails?searchText=${searchText}`);

export const GetAuthorizationStatusMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/ReferralStatus`);

export const GetReferralSourceMaster = (
  configUrl: any,
  serviceRequestTypeId: number
) =>
  API.get(
    `${configUrl}api/master/ReferralSourceFacility/${serviceRequestTypeId}`
  );

export const GetStaffingStatusMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/StaffingStatus`);

export const GetCareGiverMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/Caregiver`);

export const GetDisciplineTemplateMaster = (
  configUrl: any,
  payload: RationaleTemplatePayload
) =>
  API.get(
    `${configUrl}api/master/RationaleTemplate/${
      payload.serviceRequestDisciplineId
    }?${GetQueryString(payload)}`
  );

export const GetDischargeReasonMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/DischargeReason`);

export const diseaseTypeRespValues = (
  configUrl: string,
  diseaseIdentifier: string
) =>
  API.get(
    `${configUrl}api/master/IcdDiagnosisType?searchText=${diseaseIdentifier}`
  );

export const GetDisciplineRequestorMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/DisciplineRequestor`);

export const GetClinicalGroup = (configUrl: string) =>
  API.get(`${configUrl}api/master/GetClinicalGroup`);

export const GetPayerPlan = (configUrl: string) =>
  API.getApi(`${configUrl}Payers`);

export const GetEligibilityRecord = (
  configUrl: string,
  benefitPlanUid: string
) =>
  API.get(
    `${configUrl}api/master/BenefitPlanUid?benefitPlanUid=${benefitPlanUid}`
  );

export const GetNotesApprovalStatusMaster = (configUrl: string) =>
  API.get(`${configUrl}api/approvalStatus`);

export const GetHealthPlanMaster = (configUrl: any) =>
  API.get(`${configUrl}api/master/HealthPlan`);

export const GetBenefitPlanDetailsMaster = (
  configUrl: string,
  healthPlanId: number
) => API.get(`${configUrl}api/master/BenefitPlanDetails/${healthPlanId}`);

export const GetAllServiceStatusReasonMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/StatusReasons`);

export const GetNtuReasonMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/NtuStatusReason`);
export const GetLanguageMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/Language`);

export const GetAccomodationMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/Accommodation`);

export const GetAgencyCommunicationTemplate = (configUrl: string) =>
  API.get(`${configUrl}api/master/AgencyTemplate`);

export const GetAuthorizationType = (configUrl: string) =>
  API.get(`${configUrl}api/master/AuthorizationType`);

export const GetFaxForm = (configUrl: string) =>
  API.get(`${configUrl}api/master/FaxForm`);

export const GetFaxFormOption = (configUrl: string, formId: number) =>
  API.get(`${configUrl}api/master/FaxFormOption/${formId}`);

export const GetLetterCopyTypeMaster = (configUrl: string) =>
  API.get(`${configUrl}api/master/GetTypeIdAsync`);

export const GetServiceRequestNumberMaster = (
  configUrl: string,
  referralId: number
) => API.get(`${configUrl}api/master/ServiceRequestId/${referralId}`);

export const GetReferralMgmtSystem = (configUrl: string) =>
  API.get(`${configUrl}api/master/ReferralManagementSystem`);
