import { Dispatch, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../store/ApplicationState";
import {
  configUrlForCheckEligibility,
  configUrlForReferral,
} from "../constants/Config";
import {
  AuthorizationStatusDispatchTypes,
  CareCoordinationProgramDispatchTypes,
  CareGiverDispatchTypes,
  CareTypeDispatchTypes,
  ClinicalGroupDispatchTypes,
  CreatedByDispatchTypes,
  DischargeReasonDispatchTypes,
  DisciplineRequestorDispatchTypes,
  DisciplinesDispatchTypes,
  DisciplineTemplateDispatchTypes,
  DisciplineTypeDispatchTypes,
  DiseaseTypeDispatchTypes,
  DocumentSourceDispatchTypes,
  DocumentTypeDispatchTypes,
  EligibilityRecordDispatchTypes,
  HealthPlanDispatchTypes,
  HealthProgramDispatchTypes,
  IcdCodeTypeDispatchTypes,
  MasterCallOutcomeDispatchTypes,
  NonCoveredServicesDispatchTypes,
  OrderAcceptorDispatchTypes,
  OrderingSourceDispatchTypes,
  PastMedicalHistoryDispatchTypes,
  PayerListDispatchTypes,
  PsychologicalHistoryDispatchTypes,
  ReferralServiceStatusDispatchTypes,
  PriorAuthReferralSourceDispatchTypes,
  SeriveStatusDispatchTypes,
  StaffingStatusDispatchTypes,
  ServiceStatusReasonDispatchTypes,
  ReAuthReferralSourceDispatchTypes,
  LanguageDispatchTypes,
  AccomodationDispatchTypes,
  NtuReasonMasterDispatchTypes,
  AgencyCommunicationDispatchTypes,
  AuthorizationTypeDispatchTypes,
  FaxFormDispatchTypes,
  FaxFormOptionDispatchTypes,
  LetterCopyTypeMasterDispatchTypes,
  ServiceRequestNumberMasterDispatchTypes,
  ReferralMgmtSystemDispatchTypes,
} from "../models/DispatchTypes/Master";
import {
  AuthorizationStatusActionDispatchTypes,
  CareCoordinationProgramActionDispatchTypes,
  CareGiverActionDispatchTypes,
  CareTypeActionDispatchTypes,
  ClinicalGroupActionDispatchTypes,
  CreatedByActionDispatchTypes,
  DischargeReasonActionDispatchTypes,
  DisciplineRequestorActionDispatchTypes,
  DisciplinesActionDispatchTypes,
  DisciplineTemplateActionDispatchTypes,
  DisciplineTypeActionDispatchTypes,
  DiseaseTypeActionDispatchTypes,
  DocumentSourceActionDispatchTypes,
  DocumentTypeActionDispatchTypes,
  EligibilityRecordActionDispatchTypes,
  HealthPlanActionDispatchTypes,
  HealthProgramActionDispatchTypes,
  IcdCodeTypeActionDispatchTypes,
  MasterCallOutcomeActionDispatchTypes,
  NonCoveredServicesActionDispatchTypes,
  OrderAcceptorActionDispatchTypes,
  OrderingSourceActionDispatchTypes,
  PastMedicalHistoryActionDispatchTypes,
  PayerActionDispatchTypes,
  PsychologicalHistoryActionDispatchTypes,
  ReferralServiceStatusActionDispatchTypes,
  PriorAuthReferralSourceActionDispatchTypes,
  ServiceStatusActionDispatchTypes,
  StaffingStatusActionDispatchTypes,
  MasterAccessActionDispatchTypes,
  BenefitPlanDetailsMasterActionDispatchTypes,
  ServiceStatusReasonActionDispatchTypes,
  ReAuthReferralSourceActionDispatchTypes,
  ServiceRequestType,
  LanguageMasterActionDispatchTypes,
  AccomodationMasterActionDispatchTypes,
  NtuReasonMasterActionDispatchTypes,
  AgencyCommunicationMasterActionDispatchTypes,
  AuthorizationTypeMasterActionDispatchTypes,
  FaxFormMasterActionDispatchTypes,
  FaxFormOptionMasterActionDispatchTypes,
  LetterCopyTypeMasterActionDispatchTypes,
  ServiceRequestNumberMasterActionDispatchTypes,
  ReferralMgmtSystemActionDispatchTypes,
} from "../constants/Master";
import { MasterAccessDispatchTypes } from "../models/DispatchTypes/Master";
import {
  diseaseTypeRespValues,
  GetAuthorizationStatusMaster,
  GetCareCoordinationProgram,
  GetCareGiverMaster,
  GetCareType,
  GetClinicalGroup,
  GetDischargeReasonMaster,
  GetDisciplineRequestorMaster,
  GetDisciplines,
  GetDisciplineTemplateMaster,
  GetDisciplineType,
  GetDocumentCreatedBy,
  GetDocumentSource,
  GetDocumentType,
  GetEligibilityRecord,
  GetHealthPlanMaster,
  GetHealthProgram,
  GetMasterAccess,
  GetMasterCallOutcome,
  GetNonCoveredService,
  GetOrderAcceptor,
  GetOrderingSource,
  GetPastMedicalHistory,
  GetPayerPlan,
  GetPsychologicalHistory,
  GetReferralServiceStatusType,
  GetReferralSourceMaster,
  GetServiceStatusReason,
  GetStaffingStatusMaster,
  IcdCodeTypeData,
  GetBenefitPlanDetailsMaster,
  GetServiceStatusReasonMaster,
  GetLanguageMaster,
  GetAccomodationMaster,
  GetNtuReasonMaster,
  GetAgencyCommunicationTemplate,
  GetAuthorizationType,
  GetFaxForm,
  GetFaxFormOption,
  GetLetterCopyTypeMaster,
  GetServiceRequestNumberMaster,
  GetReferralMgmtSystem,
} from "../services/Master";
import { configUrlForUserManagement } from "../constants/Config";
import { RationaleTemplatePayload } from "../models/Master";
import { BenefitPlanDetailsDispatchTypes } from "../models/DispatchTypes/Patient";
import { DisciplineSearch } from "../models/Service";

export const getDisciplines: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplinesDispatchTypes
  >
> = (payload: DisciplineSearch) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplinesActionDispatchTypes.DISCIPLINES_INVOKE,
    });

    const response = await GetDisciplines(configUrlForReferral, payload);

    dispatch({
      response,
      type: DisciplinesActionDispatchTypes.DISCIPLINES_SUCCESS,
    });
  };
};

export const getCareType: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CareTypeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CareTypeActionDispatchTypes.CARE_TYPE_INVOKE,
    });

    const response = await GetCareType(configUrlForReferral);

    dispatch({
      response,
      type: CareTypeActionDispatchTypes.CARE_TYPE_SUCCESS,
    });
  };
};

export const getPastMedicalHistory: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PastMedicalHistoryDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PastMedicalHistoryActionDispatchTypes.PAST_MEDICAL_HISTORY_INVOKE,
    });

    const response = await GetPastMedicalHistory(configUrlForReferral);

    dispatch({
      response,
      type: PastMedicalHistoryActionDispatchTypes.PAST_MEDICAL_HISTORY_SUCCESS,
    });
  };
};

export const getPsychologicalHistory: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PsychologicalHistoryDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PsychologicalHistoryActionDispatchTypes.PSYCHOLOGICAL_HISTORY_INVOKE,
    });

    const response = await GetPsychologicalHistory(configUrlForReferral);

    dispatch({
      response,
      type: PsychologicalHistoryActionDispatchTypes.PSYCHOLOGICAL_HISTORY_SUCCESS,
    });
  };
};

export const getMasterAccess: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    MasterAccessDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MasterAccessActionDispatchTypes.MASTER_ACCESS_INVOKE,
    });

    const response = await GetMasterAccess(configUrlForUserManagement);
    dispatch({
      response: response,
      type: MasterAccessActionDispatchTypes.MASTER_ACCESS_SUCCESS,
    });
  };
};

export const getMasterCallOutcome: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    MasterCallOutcomeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: MasterCallOutcomeActionDispatchTypes.MASTER_CALLOUTCOME_INVOKE,
    });

    const response = await GetMasterCallOutcome(configUrlForReferral);
    dispatch({
      response: response,
      type: MasterCallOutcomeActionDispatchTypes.MASTER_CALLOUTCOME_SUCCESS,
    });
  };
};

export const getIcdCodeTypeData: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    IcdCodeTypeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: IcdCodeTypeActionDispatchTypes.ICD_CODE_TYPE_INVOKE,
    });

    const response = await IcdCodeTypeData(configUrlForReferral);
    dispatch({
      response: response,
      type: IcdCodeTypeActionDispatchTypes.ICD_CODE_TYPE_SUCCESS,
    });
  };
};

export const getDiseaseTypeCall: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DiseaseTypeDispatchTypes
  >
> = (diseaseIdentifier: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DiseaseTypeActionDispatchTypes.DISEASE_TYPE_INVOKE,
    });

    const response = await diseaseTypeRespValues(
      configUrlForReferral,
      diseaseIdentifier
    );
    if (response) {
      dispatch({
        response: response,
        type: DiseaseTypeActionDispatchTypes.DISEASE_TYPE_SUCCESS,
      });
    } else {
      dispatch({
        reponse: [],
        type: DiseaseTypeActionDispatchTypes.DISEASE_TYPE_SUCCESS,
      });
    }
  };
};

export const getReferralMgmtSystem: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralMgmtSystemDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralMgmtSystemActionDispatchTypes.REFERRALMGMTSYSTEM_INVOKE,
    });

    const response = await GetReferralMgmtSystem(configUrlForReferral);
    dispatch({
      response: response,
      type: ReferralMgmtSystemActionDispatchTypes.REFERRALMGMTSYSTEM_SUCCESS,
    });
  };
};

export const getHealthProgram: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    HealthProgramDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: HealthProgramActionDispatchTypes.HEALTH_PROGRAM_INVOKE,
    });

    const response = await GetHealthProgram(configUrlForReferral);
    dispatch({
      response: response,
      type: HealthProgramActionDispatchTypes.HEALTH_PROGRAM_SUCCESS,
    });
  };
};

export const getCareCoordinationProgram: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CareCoordinationProgramDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CareCoordinationProgramActionDispatchTypes.CARECORDINATION_PROGRAM_INVOKE,
    });

    const response = await GetCareCoordinationProgram(configUrlForReferral);
    dispatch({
      response: response,
      type: CareCoordinationProgramActionDispatchTypes.CARECORDINATION_PROGRAM_SUCCESS,
    });
  };
};

export const getNonCoveredService: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    NonCoveredServicesDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NonCoveredServicesActionDispatchTypes.NONCOVERED_SERVICES_INVOKE,
    });

    const response = await GetNonCoveredService(configUrlForReferral);
    dispatch({
      response: response,
      type: NonCoveredServicesActionDispatchTypes.NONCOVERED_SERVICES_SUCCESS,
    });
  };
};

export const getOrderingSource: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    OrderingSourceDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OrderingSourceActionDispatchTypes.ORDERING_SOURCE_INVOKE,
    });

    const response = await GetOrderingSource(configUrlForReferral);
    dispatch({
      response: response,
      type: OrderingSourceActionDispatchTypes.ORDERING_SOURCE_SUCCESS,
    });
  };
};

export const getOrderAcceptor: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    OrderAcceptorDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: OrderAcceptorActionDispatchTypes.ORDER_ACCEPTOR_INVOKE,
    });

    const response = await GetOrderAcceptor(configUrlForReferral);
    dispatch({
      response: response,
      type: OrderAcceptorActionDispatchTypes.ORDER_ACCEPTOR_SUCCESS,
    });
  };
};

// AUTH STATUS
export const getAuthorizationStatusMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthorizationStatusDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthorizationStatusActionDispatchTypes.AUTHORIZATION_STATUS_INVOKE,
    });
    const response = await GetAuthorizationStatusMaster(configUrlForReferral);

    dispatch({
      response,
      type: AuthorizationStatusActionDispatchTypes.AUTHORIZATION_STATUS_SUCCESS,
    });
  };
};

// Referral Source

export const getPriorAuthReferralSourceMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PriorAuthReferralSourceDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PriorAuthReferralSourceActionDispatchTypes.PRIOR_AUTH_REFERRAL_SOURCE_INVOKE,
    });
    const response = await GetReferralSourceMaster(
      configUrlForReferral,
      ServiceRequestType.AUTH_ID
    );

    dispatch({
      response,
      type: PriorAuthReferralSourceActionDispatchTypes.PRIOR_AUTH_REFERRAL_SOURCE_SUCCESS,
    });
  };
};

export const getReAuthReferralSourceMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReAuthReferralSourceDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReAuthReferralSourceActionDispatchTypes.RE_AUTH_REFERRAL_SOURCE_INVOKE,
    });
    const response = await GetReferralSourceMaster(
      configUrlForReferral,
      ServiceRequestType.RE_AUTH_ID
    );

    dispatch({
      response,
      type: ReAuthReferralSourceActionDispatchTypes.RE_AUTH_REFERRAL_SOURCE_SUCCESS,
    });
  };
};

// Staffing Status

export const getStaffingStatusMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    StaffingStatusDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: StaffingStatusActionDispatchTypes.STAFFING_STATUS_INVOKE,
    });
    const response = await GetStaffingStatusMaster(configUrlForReferral);

    dispatch({
      response,
      type: StaffingStatusActionDispatchTypes.STAFFING_STATUS_SUCCESS,
    });
  };
};

// careGiver

export const getCareGiverMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CareGiverDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CareGiverActionDispatchTypes.CARE_GIVER_INVOKE,
    });
    const response = await GetCareGiverMaster(configUrlForReferral);

    dispatch({
      response,
      type: CareGiverActionDispatchTypes.CARE_GIVER_SUCCESS,
    });
  };
};

// Discharge Reason

export const getDischargeReasonMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DischargeReasonDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DischargeReasonActionDispatchTypes.DISCHARGE_REASON_INVOKE,
    });
    const response = await GetDischargeReasonMaster(configUrlForReferral);

    dispatch({
      response,
      type: DischargeReasonActionDispatchTypes.DISCHARGE_REASON_SUCCESS,
    });
  };
};

export const getDocumentType: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DocumentTypeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DocumentTypeActionDispatchTypes.DOCUMENT_TYPE_INVOKE,
    });

    const response = await GetDocumentType(configUrlForReferral);
    dispatch({
      response: response,
      type: DocumentTypeActionDispatchTypes.DOCUMENT_TYPE_SUCCESS,
    });
  };
};

export const getDocumentSource: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DocumentSourceDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DocumentSourceActionDispatchTypes.DOCUMENT_SOURCE_INVOKE,
    });

    const response = await GetDocumentSource(configUrlForReferral);
    dispatch({
      response: response,
      type: DocumentSourceActionDispatchTypes.DOCUMENT_SOURCE_SUCCESS,
    });
  };
};

export const getDocumentCreatedBy: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    CreatedByDispatchTypes
  >
> = (searchText: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: CreatedByActionDispatchTypes.CREATED_BY_INVOKE,
    });

    const response = await GetDocumentCreatedBy(
      configUrlForReferral,
      searchText
    );
    dispatch({
      response: response,
      type: CreatedByActionDispatchTypes.CREATED_BY_SUCCESS,
    });
  };
};

export const getReferralServiceStatusResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ReferralServiceStatusDispatchTypes
  >
> = (requiredIncompleteOption: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_INVOKE,
    });

    const response = await GetReferralServiceStatusType(
      configUrlForReferral,
      requiredIncompleteOption
    );
    dispatch({
      response: response,
      type: ReferralServiceStatusActionDispatchTypes.REFERRAL_SERVICE_STATUS_SUCCESS,
    });
  };
};
/**new filter */
export const getServiceStatusReasonMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceStatusReasonDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceStatusReasonActionDispatchTypes.SERVICE_REASON_STATUS_INVOKE,
    });

    const response = await GetServiceStatusReasonMaster(configUrlForReferral);
    dispatch({
      response: response,
      type: ServiceStatusReasonActionDispatchTypes.SERVICE_REASON_STATUS_SUCCESS,
    });
  };
};

export const getDisciplineTypeResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineTypeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplineTypeActionDispatchTypes.DISCIPLINE_TYPE_INVOKE,
    });

    const response = await GetDisciplineType(configUrlForReferral);
    dispatch({
      response: response,
      type: DisciplineTypeActionDispatchTypes.DISCIPLINE_TYPE_SUCCESS,
    });
  };
};

export const getServiceStatusReasonResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    SeriveStatusDispatchTypes
  >
> = (serviceId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceStatusActionDispatchTypes.SERVICE_STATUS_INVOKE,
    });

    const response = await GetServiceStatusReason(
      configUrlForReferral,
      serviceId
    );
    dispatch({
      response: response,
      type: ServiceStatusActionDispatchTypes.SERVICE_STATUS_SUCCESS,
    });
  };
};

export const getDisciplineTemplateResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineTemplateDispatchTypes
  >
> = (payload: RationaleTemplatePayload) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplineTemplateActionDispatchTypes.DISCIPLINE_TEMPLATE_INVOKE,
    });

    const response = await GetDisciplineTemplateMaster(
      configUrlForReferral,
      payload
    );
    dispatch({
      response: response,
      type: DisciplineTemplateActionDispatchTypes.DISCIPLINE_TEMPLATE_SUCCESS,
    });
  };
};

export const getDisciplineRequestorResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    DisciplineRequestorDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: DisciplineRequestorActionDispatchTypes.DISCIPLINE_REQUESTOR_INVOKE,
    });

    const response = await GetDisciplineRequestorMaster(configUrlForReferral);
    dispatch({
      response: response,
      type: DisciplineRequestorActionDispatchTypes.DISCIPLINE_REQUESTOR_SUCCESS,
    });
  };
};

export const getClinicalGroup: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ClinicalGroupDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ClinicalGroupActionDispatchTypes.CLINICAL_GROUP_INVOKE,
    });
    const response = await GetClinicalGroup(configUrlForReferral);

    dispatch({
      response,
      type: ClinicalGroupActionDispatchTypes.CLINICAL_GROUP_SUCCESS,
    });
  };
};

export const getMasterEligibilityPayer: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    PayerListDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: PayerActionDispatchTypes.PAYER_INVOKE,
    });

    const response = await GetPayerPlan(configUrlForCheckEligibility);

    dispatch({
      response,
      type: PayerActionDispatchTypes.PAYER_SUCCESS,
    });
  };
};

export const getEligibilityRecord: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    EligibilityRecordDispatchTypes
  >
> = (benefitPlanUid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_INVOKE,
    });
    const response = await GetEligibilityRecord(
      configUrlForReferral,
      benefitPlanUid
    );

    dispatch({
      response,
      type: EligibilityRecordActionDispatchTypes.ELIGIBILITY_RECORD_SUCCESS,
    });
  };
};

export const getHealthPlanMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    HealthPlanDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: HealthPlanActionDispatchTypes.HEALTH_PLAN_MASTER_INVOKE,
    });
    const response = await GetHealthPlanMaster(configUrlForReferral);

    dispatch({
      response,
      type: HealthPlanActionDispatchTypes.HEALTH_PLAN_MASTER_SUCCESS,
    });
  };
};

export const GetBenefitPlanDetailsMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    BenefitPlanDetailsDispatchTypes
  >
> = (healthPlanId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: BenefitPlanDetailsMasterActionDispatchTypes.BENEFIT_PLAN_DETAILS_MASTER_INVOKE,
    });
    const response = await GetBenefitPlanDetailsMaster(
      configUrlForReferral,
      healthPlanId
    );

    dispatch({
      response,
      type: BenefitPlanDetailsMasterActionDispatchTypes.BENEFIT_PLAN_DETAILS_MASTER_SUCCESS,
    });
  };
};

export const GetLanguageMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    LanguageDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LanguageMasterActionDispatchTypes.LANGUAGE_MASTER_INVOKE,
    });
    const response = await GetLanguageMaster(configUrlForReferral);

    dispatch({
      response,
      type: LanguageMasterActionDispatchTypes.LANGUAGE_MASTER_SUCCESS,
    });
  };
};

export const GetAccomodationMasterResp: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AccomodationDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AccomodationMasterActionDispatchTypes.ACCOMODATION_MASTER_INVOKE,
    });
    const response = await GetAccomodationMaster(configUrlForReferral);

    dispatch({
      response,
      type: AccomodationMasterActionDispatchTypes.ACCOMODATION_MASTER_SUCCESS,
    });
  };
};

export const GetNtuReasonMasterResponse: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    NtuReasonMasterDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: NtuReasonMasterActionDispatchTypes.GET_NTU_REASON_INVOKE,
    });
    const response = await GetNtuReasonMaster(configUrlForReferral);

    dispatch({
      response,
      type: NtuReasonMasterActionDispatchTypes.GET_NTU_REASON_SUCCESS,
    });
  };
};

export const GetAgencyTemplateMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AgencyCommunicationDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AgencyCommunicationMasterActionDispatchTypes.GET_AGENCY_COMMUNICATION_TEMPLATE_INVOKE,
    });
    const response = await GetAgencyCommunicationTemplate(configUrlForReferral);

    dispatch({
      response,
      type: AgencyCommunicationMasterActionDispatchTypes.GET_AGENCY_COMMUNICATION_TEMPLATE_SUCCESS,
    });
  };
};

export const GetAuthorizationTypeMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    AuthorizationTypeDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: AuthorizationTypeMasterActionDispatchTypes.AUTHORIZATION_TYPE_INVOKE,
    });
    const response = await GetAuthorizationType(configUrlForReferral);

    dispatch({
      response,
      type: AuthorizationTypeMasterActionDispatchTypes.AUTHORIZATION_TYPE_SUCCESS,
    });
  };
};

export const getFaxFormMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    FaxFormDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FaxFormMasterActionDispatchTypes.FAX_FORM_MASTER_INVOKE,
    });
    const response = await GetFaxForm(configUrlForReferral);

    dispatch({
      response,
      type: FaxFormMasterActionDispatchTypes.FAX_FORM_MASTER_SUCCESS,
    });
  };
};
export const getLetterCopyTypeMasterResponse: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    LetterCopyTypeMasterDispatchTypes
  >
> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LetterCopyTypeMasterActionDispatchTypes.GET_LETTER_COPY_TYPE_MASTER_INVOKE,
    });
    const response = await GetLetterCopyTypeMaster(configUrlForReferral);

    dispatch({
      response,
      type: LetterCopyTypeMasterActionDispatchTypes.GET_LETTER_COPY_TYPE_MASTER_SUCCESS,
    });
  };
};

export const getFaxFormOptionMaster: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    FaxFormOptionDispatchTypes
  >
> = (formId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_INVOKE,
    });

    const response = await GetFaxFormOption(configUrlForReferral, formId);

    dispatch({
      response,
      type: FaxFormOptionMasterActionDispatchTypes.GET_FAX_FORM_OPTION_MASTER_SUCCESS,
    });
  };
};

export const getServiceRequestNumberMasterResponse: ActionCreator<
  ThunkAction<
    void,
    ApplicationState,
    Record<string, never>,
    ServiceRequestNumberMasterDispatchTypes
  >
> = (referralId: number) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ServiceRequestNumberMasterActionDispatchTypes.GET_SERVICE_REQUEST_NUMBER_MASTER_INVOKE,
    });
    const response = await GetServiceRequestNumberMaster(
      configUrlForReferral,
      referralId
    );

    dispatch({
      response,
      type: ServiceRequestNumberMasterActionDispatchTypes.GET_SERVICE_REQUEST_NUMBER_MASTER_SUCCESS,
    });
  };
};
