import { Reducer } from "redux";

import { ReviewActionDispatchTypes } from "../constants/Review";
import { ReviewDispatchTypes } from "../models/DispatchTypes/Review";
import { ReviewResponse } from "../models/Api/Review";
import { PhysicianTypeID } from "../constants/PhysicianDetails";

const initialState: ReviewResponse = {
  loading: false,
  response: {
    referralDetail: {
      referralId: 0,
      patientId: 0,
      receivedDateTime: "",
      urgencyId: 0,
      receivedMethodId: 0,
      receivedMethodName: "",
      isSignedHealthOrder: "",
      signedOrderDate: "",
      signedOrderType: "",
      orderedById: 0,
      acceptedById: 0,
      isCustodialCare: "",
      isNonCoveredService: "",
      userCreated: null,
      nonCoveredServices: "",
      healthProgramId: 0,
      careProgramId: 0,
      urgencyStatus: "",
      orderedByName: "",
      acceptedByName: "",
      nonCoveredServiceNames: "",
      patientEligibilityId: 0,
      authorizationTypeId: 0,
      authorizationTypeName: "",
    },
    emergencyContact: [
      {
        contactName: "",
        relationId: 0,
        relationshipName: "",
        phoneNo: "",
        extension: "",
        preferredLanguage: "",
      },
    ],
    address: {
      addressTypeId: 3,
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      patientState: null,
      zipCode: null,
      latitude: null,
      longitude: null,
    },
    requestor: {
      facilityTypeId: 0,
      requesterName: "",
      requesterExtension: "",
      requesterPhoneNo: "",
      requesterEmail: null,
      requesterFax: "",
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      requesterState: null,
      zipCode: null,
      facilityTypeName: "",
    },
    referralSource: {
      facilityTypeId: 0,
      referralSourceName: "",
      referralSourceExtension: "",
      referralSourcePhoneNo: "",
      referralSourceEmail: "",
      referralSourceFax: null,
      streetName1: null,
      streetName2: null,
      county: null,
      city: null,
      referralSourceState: null,
      zipCode: null,
      sameAsRequestingFacility: false,
      dischargeDate: "",
      facilityTypeName: "",
    },

    icdCode: [
      {
        referralIcdCodeId: 0,
        diagnosisTypeId: 0,
        diagnosisCode: "",
        diagnosisCodeDescription: "",
        icdDiagnosisCodeId: 0,
      },
    ],
    primaryPhysician: {
      referralId: 0,
      referralPhysicianDetailId: 0,
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      phoneNo: "",
      phoneExt: "",
      taxId: "",
      streetName: "",
      county: "",
      city: "",
      physicianState: "",
      zipCode: "",
      userCreated: null,
      physicianAddress: "",
      state: "",
      physicianTypeId: PhysicianTypeID.PRIMARY,
    },
    orderingPhysician: {
      referralId: 0,
      referralPhysicianDetailId: 0,
      physicianId: 0,
      physicianName: "",
      physicianNpiId: "",
      phoneExt: "",
      phoneNo: "",
      taxId: "",
      streetName1: "",
      streetName2: "",
      fax: "",
      county: "",
      city: "",
      state: "",
      zipCode: "",
      physicianAddress: "",
      physicianState: "",
      physicianTypeId: PhysicianTypeID.ORDERING,
      physicianSameAsPrimary: false,
    },
    serviceProvider: {
      serviceProviderId: 0,
      providerName: "",
      referralId: 0,
      providerNpi: "",
      providerAddress: "",
      streetName1: "",
      streetName2: "",
      providerCity: "",
      providerCounty: "",
      providerState: "",
      zipCode: "",
      extension: "",
      phone: "",
      taxId: "",
      fax: "",
      parentCompany: 0,
      ahcccsId: "",
      medicareId: "",
      contractEffectiveFrom: "",
      contractEffectiveTo: "",
      userCreated: null,
      symplrId: 0,
      serviceProviderUid: "",
      country: "",
      email: "",
      plexisOfficeId: 0,
      providerId: 0,
      reimbursementTypeId: null,
      reimbursementTypeName: null
    },
    serviceRequest: {
      descipline: [],
      careTypes: [],
      pastMedicalHistory: [],
      psychologicalHistory: [],
    },
    referralDocument: {
      id: 0,
      documentName: [],
      containsSupportedDoc: false,
      documentationDate: "",
      documentId: [],
      userCreated: null,
      type: 0,
    },
    blobDocument: [
      {
        blobId: 0,
        blobFileName: "",
        blobUIdstring: "",
        blobContentType: "",
        blobUri: "",
        dateTimeReceived: "",
        documentSourceName: "",
        documentSourceId: null,
        documentId: null,
        userCreated: null,
        originalDocumentName: "",
        userFullName: null,
        showDateTime: true,
      },
    ],
  },
};

const ReviewReducer: Reducer<ReviewResponse, ReviewDispatchTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ReviewActionDispatchTypes.REVIEW_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ReviewActionDispatchTypes.REVIEW_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ReviewReducer;
