import { Backdrop, CircularProgress } from "@mui/material";

import { LoadingSection } from "../styles/style";

export const Loading = () => {
  return (
    <Backdrop sx={LoadingSection} open={true}>
      <CircularProgress />
    </Backdrop>
  );
};
