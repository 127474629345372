import { isUndefined } from "lodash";

import { getProviderListPayload, ServiceRequest } from "../models/Service";
import { GetQueryString } from "../utils";
import API from "./api";

export const GetServiceRequest = (
  configUrl: string,
  referralId: number,
  startOfCareDate?: string
) => {
  if (isUndefined(startOfCareDate)) {
    return API.get(`${configUrl}api/servicerequest/${referralId}`);
  } else {
    return API.get(
      `${configUrl}api/servicerequest/${referralId}?startOfCareDate=${startOfCareDate}`
    );
  }
};

export const PostServiceRequest = (
  configUrl: string,
  payload: ServiceRequest
) => {
  return API.postApi(`${configUrl}api/servicerequest`, payload);
};

export const PutServiceRequest = (configUrl: string, payload: any) => {
  return API.putApi(
    `${configUrl}api/servicerequest?referralId=${payload.referralId}`,
    payload
  );
};

export const GetServiceProvider = (configUrl: string, payload: getProviderListPayload) =>
  API.get(`${configUrl}api/serviceprovider?${GetQueryString(payload)}`);


export const GetServiceProviderMaster = (configUrl: string, value: string) => {
  const suffix = `?searchText=${value}`;
  return API.get(`${configUrl}api/master/GetServiceProvider${suffix}`);
};

export const GetPatientState = (configUrl: string, suffix: string) =>
  API.get(`${configUrl}api/master/GetPatientStates${suffix}`);

export const GetCounty = (configUrl: string, value: string) => {
  const suffix = `?searchText=${value}`;
  return API.get(`${configUrl}api/master/GetPatientCounty${suffix}`);
}


export const GetServiceProviderById = (configUrl: string, referralId: string) =>
  API.get(`${configUrl}api/serviceprovider/${referralId}`);

export const PutServiceProvider = (
  configUrl: string,
  referralId: string,
  data: any
) => API.putApi(`${configUrl}api/serviceprovider${referralId}`, data);

export const GetServiceStatusByType = (value: number, configUrl: string) =>
  API.get(`${configUrl}api/disciplines/ServiceStatus/${value}`);

export const PostServiceProviderDetails = (configUrl: string, data: any) =>
  API.postApi(`${configUrl}api/serviceprovider`, data);
