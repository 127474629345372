import { Reducer } from "redux";

import { DisciplinesResponse } from "../models/Api/Master";
import { DisciplinesActionDispatchTypes } from "../constants/Master";
import { DisciplinesDispatchTypes } from "../models/DispatchTypes/Master";

const initialState: DisciplinesResponse = {
  loading: false,
  response: [
    {
      disciplineId: -1,
      disciplineName: "",
      azCompeleteCheck: false,
    },
  ],
};

const DisciplinesReducer: Reducer<
  DisciplinesResponse,
  DisciplinesDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case DisciplinesActionDispatchTypes.DISCIPLINES_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case DisciplinesActionDispatchTypes.DISCIPLINES_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case DisciplinesActionDispatchTypes.DISCIPLINES_RESET: {
      return {
        response: initialState.response,
        loading: false,
      };
    }
  }
  return state;
};

export default DisciplinesReducer;
