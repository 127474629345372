import { Reducer } from "redux";

import { PostServiceRequestResponse } from "../models/Api/Service";
import { PostServiceRequestDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceRequestActionDispatchType } from "../constants/Service";

const initialState: PostServiceRequestResponse = {
  loading: false,
  response: {
    referralId: 0,
    userCreated: null,
    startOfCareDate: "",
    referralDisciplineDto: [],
    referralCareTypeDto: [],
    diseasesDto: [],
    psychologicalDiseasesDto: [],
    currentEndDate: null,
    currentStartDate: null
  },
  error: null,
};

const ServiceRequestReducer: Reducer<
  PostServiceRequestResponse,
  PostServiceRequestDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
        error: {},
      };
    }
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_ERROR: {
      return {
        ...state,
        error: action.response,
        loading: false,
      };
    }
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_POST_RESET: {
      return {
        ...state,
        response: initialState.response,
        loading: false,
      };
    }
  }
  return state;
};

export default ServiceRequestReducer;
