import { Box, Tooltip, Typography } from "@mui/material";
import { FaDownload } from "react-icons/fa";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import { avator, length } from "../../utils";
import { colors } from "../../styles/colors";

import {
  acceptStyle,
  BrowsButtonUI,
  DocumentRoot,
  dragAndDropStyle,
  FaDownloadIcon,
  fileName,
  FileText,
  FileUploadAvatar,
  PatientImageMain,
  PatientImageOR,
  PatientImageRoot,
  ReferralDocList,
  ReferralDocumentList,
  ReferralDocMain,
  ReferralImageMain,
  rejectStyle,
  RiDeleteIcon,
  UserProfileAvatar,
  VerticalDivider,
} from "./styles/style";
import {
  flexAlignCentre,
  flexAllCentre,
  justifyContentRight,
  justifyContentSpaceBetween,
} from "../../styles/mui/styles/display";
import fontWeight from "../../styles/mui/fontWeight";
import { defaultImage } from "../../constants/ImagePaths";
import { MessageType, ResponseMessages } from "../../constants/ToastMessage";
import { documentFaxMaxSize } from "../../constants/Config";

interface fileData {
  disabled?: boolean;
  fileName?: any;
  files: any;
  imageUrl?: string;
  inputType: any;
  patientImageProps?: any;
  setFiles: any;
  setPatientImage?: any;
  maxFileSizeInBytes?: number;
  minFileSize?: number;
  type: number;
}

type AllProps = fileData;

const FileUpload: React.FC<AllProps> = ({
  disabled,
  files,
  imageUrl,
  inputType,
  patientImageProps,
  setFiles,
  setPatientImage,
  maxFileSizeInBytes,
  minFileSize,
  type,
}: AllProps) => {
  const allowedFileTypes = [".doc", ".docx", ".pdf", ".xls", ".xlsx"];
  const imageFileTypes = [".png", ".jpg", ".jpeg"];
  const portableFileTypes = [".pdf"];
  const faxFiletypes = {
    "image/png": imageFileTypes,
    "image/jpeg": imageFileTypes,
    "image/jpg": imageFileTypes,
    "application/pdf": portableFileTypes,
  };

  const portableFiletypes = {
    "application/pdf": portableFileTypes,
  };

  const acceptedFileType = (type: number) => {
    switch (type) {
      case 0:
        return faxFiletypes;
      case 2:
        return portableFiletypes;
      case 1:
      default:
        return {
          ...faxFiletypes,
          "text/plain": [".txt"],
          "application/vnd.ms-excel": allowedFileTypes,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            allowedFileTypes,
          "application/msword": allowedFileTypes,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            allowedFileTypes,
        };
    }
  };

  const fileSizeValidator = (file: File) => {
    const storedFilesSizeArray = files.map((data: File) =>
      Number((data.size / (1024 * 1024)).toFixed(2))
    );
    const sumFileSize = storedFilesSizeArray.reduce(
      (accumulator: number, currentValue: number) => accumulator + currentValue,
      0
    );

    const maxFileSizeInMb = (file.size / (1024 * 1024)).toFixed(2);

    if (
      Math.ceil(Number(maxFileSizeInMb) + Number(sumFileSize)) >=
      Number(documentFaxMaxSize)
    ) {
      return {
        code: "name-too-large",
        message: ResponseMessages(MessageType.SEND_FAX_MAX_SIZE),
      };
    }

    return null;
  };
  const {
    getRootProps: getRootReferralFileProps,
    getInputProps: getReferralFileProps,
    isDragAccept,
    isDragReject,
    open,
    fileRejections,
  } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
          const removeDuplicate = files.some(
            (file: { path: any }) => file.path === acceptedFiles[0].path
          );
          if (removeDuplicate === false) {
            type === 0
              ? setFiles([...files, acceptedFiles[0]])
              : setFiles([acceptedFiles[0]]);
          }
        }
      },
      [files]
    ),
    validator: fileSizeValidator,
    accept: {
      ...acceptedFileType(type),
    },
    maxFiles: 1,
    maxSize: maxFileSizeInBytes,
    minSize: minFileSize,
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  const fileRejectionItems = fileRejections.map(({ errors }, index) => {
    const minFileSizeForUpload = minFileSize ? minFileSize : 1;
    const maxFileSizeInMegaBytes = maxFileSizeInBytes
      ? maxFileSizeInBytes / 1000000
      : 1000000;
    return (
      <Box key={index} textAlign={"center"} mt="1rem">
        {errors.map((e: any) => {
          if (e.code === "file-invalid-type") {
            e.message = "File type not allowed";
          } else if (e.code === "file-too-large") {
            e.message = `File is larger than ${maxFileSizeInMegaBytes} mb`;
          } else if (e.code === "file-too-small") {
            e.message = `File size should be greater than ${minFileSizeForUpload-1} kb`;
          }
          return (
            <Typography
              variant="body1"
              color={colors.red[100]}
              key={e.code}
              fontWeight={fontWeight.Weight[3]}
              pb={"0.7rem"}
            >
              {e.message}
            </Typography>
          );
        })}
      </Box>
    );
  });

  const style = useMemo(
    () => ({
      ...dragAndDropStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragAccept, isDragReject]
  );

  const {
    getRootProps: getRootPatientFileProps,
    getInputProps: getPatientFileProps,
  } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: any) => {
        if (acceptedFiles.length > 0) {
          const removeDuplicate = files.some(
            (file: { path: any }) => file.path === acceptedFiles[0].path
          );
          if (removeDuplicate === false && files.length == 0) {
            setFiles([...files, acceptedFiles[0]]);
          }
        }
      },
      [files]
    ),
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
      "application/pdf": [".pdf", ".doc", ".docx"],
    },
    maxFiles: 1,
    multiple: false,
  });

  const {
    getRootProps: getRootPatientImageProps,
    getInputProps: getPatientImageProps,
  } = useDropzone({
    onDrop: useCallback(
      (acceptedFiles: any) => {
        setPatientImage([...patientImageProps, acceptedFiles[0]]);
        if (acceptedFiles.length > 0) {
          const removeDuplicate = files.some(
            (file: { path: any }) => file.path === acceptedFiles[0].path
          );
          if (removeDuplicate === false) {
            setFiles([acceptedFiles[0]]);
          }
        }
      },
      [files]
    ),
    accept: {
      "image/png": [".png", ".jpg"],
    },
    disabled: disabled || false,
    multiple: false,
  });

  const patientDocument = () => {
    return (
      <Grid container>
        <Grid item xs={12} sx={PatientImageRoot}>
          <Grid container sx={ReferralImageMain}>
            <Grid item xs={12} sx={flexAllCentre}>
              <Grid container {...getRootPatientFileProps()}>
                <input className="input-zone" {...getPatientFileProps()} />
                <Grid item xs={5.75} sx={flexAllCentre}>
                  <Grid container sx={flexAllCentre}>
                    <Grid item xs={3} sx={flexAllCentre}>
                      <FaDownload color={colors.fonts[18]} />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        variant="body1"
                        color={colors.fonts[4]}
                        fontWeight={fontWeight.Weight[3]}
                      >
                        Drop File Here
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={0.25} sx={flexAllCentre}>
                  <Divider
                    orientation="vertical"
                    variant="fullWidth"
                    flexItem
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container sx={flexAllCentre}>
                    <Grid item xs={5} sx={flexAllCentre}>
                      <Typography
                        variant="body1"
                        color={colors.fonts[4]}
                        fontWeight={fontWeight.Weight[3]}
                      >
                        OR
                      </Typography>
                    </Grid>
                    <Grid item xs={7} sx={flexAllCentre}>
                      <Button variant="outlined" sx={BrowsButtonUI}>
                        Browse
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const patientImage = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container sx={PatientImageMain}>
            <Grid item xs={6}>
              {" "}
              {/*  {...getRootPatientImageProps()}  TBD to select image */}
              {/* <input {...getPatientImageProps()}/> TBD to select image */}
              <Grid container>
                <Grid item xs={2}>
                  <FaDownloadIcon />
                </Grid>
                <Grid item xs={10}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container sx={flexAllCentre}>
                        <Grid item sx={flexAllCentre} p={".2rem"}>
                          <Typography
                            variant="body1"
                            color={colors.fonts[4]}
                            fontWeight={fontWeight.Weight[3]}
                          >
                            Drop File Here
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={PatientImageOR}>
                      <Typography
                        variant="body1"
                        color={colors.fonts[4]}
                        fontWeight={fontWeight.Weight[3]}
                      >
                        OR
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sx={flexAllCentre}>
                      <Button variant="outlined" sx={BrowsButtonUI}>
                        Browse
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                sx={VerticalDivider}
              />
            </Grid>
            <Grid item xs={5} pl={"0.8rem"}>
              <Grid container>
                <Grid item xs={9} sx={flexAllCentre}>
                  <IconButton>
                    <Avatar
                      src={
                        files && length(files)
                          ? URL.createObjectURL(files[0])
                          : avator(defaultImage)
                      }
                      sx={FileUploadAvatar}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={3} sx={flexAllCentre}>
                  <RiDeleteIcon onClick={() => setFiles([])} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const userProfile = () => {
    return (
      <Grid container justifyContent={"flex-end"}>
        <Grid
          item
          xs={9}
          sx={[flexAlignCentre, justifyContentRight]}
          {...getRootPatientImageProps()}
        >
          <Tooltip title={disabled ? "" : "change"}>
            <IconButton>
              <input {...getPatientImageProps()} />
              {imageUrl ? (
                <Avatar src={imageUrl} sx={UserProfileAvatar} />
              ) : (
                <Avatar
                  src={
                    files && length(files)
                      ? URL.createObjectURL(files[0])
                      : avator(defaultImage)
                  }
                  sx={UserProfileAvatar}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const ReferralDocument = () => {
    return (
      <Box sx={DocumentRoot}>
        <Box>
          <Grid container sx={[flexAllCentre, ReferralDocMain]}>
            <Grid
              item
              xs={5}
              {...getRootReferralFileProps({
                style,
              })}
            >
              <Box>
                <input className="input-zone" {...getReferralFileProps()} />
                <Box>
                  <Box sx={flexAllCentre}>
                    <FaDownload
                      color={colors.fonts[18]}
                      size={"1rem"}
                      data-testid="drop-file"
                    />
                  </Box>
                  <Box sx={FileText}>
                    <Typography
                      variant="body1"
                      color={colors.fonts[4]}
                      fontWeight={fontWeight.Weight[5]}
                    >
                      DROP FILE HERE
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={2} sx={flexAllCentre}>
              <Typography
                variant="body1"
                color={colors.fonts[4]}
                fontWeight={fontWeight.Weight[4]}
              >
                OR
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid container sx={flexAllCentre}>
                <Grid item p="1rem" xs={12} sx={flexAllCentre}>
                  <Button
                    variant="outlined"
                    onClick={open}
                    sx={BrowsButtonUI}
                    data-testid={`${inputType}`}
                  >
                    Browse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={files.length > 3 ? ReferralDocList : ReferralDocumentList}>
            {files &&
              length(files) &&
              files.map((item: any, index: number) => {
                return (
                  <Box
                    key={index}
                    pt=".5rem"
                    sx={[flexAlignCentre, justifyContentSpaceBetween]}
                  >
                    <Box sx={fileName}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={fontWeight.Weight[5]}
                        color={colors.fonts[4]}
                        mr={"1rem"}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                    <Box>
                      <RiDeleteIcon
                        onClick={() =>
                          setFiles(
                            files.filter((file: any) => file.name !== item.name)
                          )
                        }
                        data-testid={`delete-attached-doc-${index}`}
                      />
                    </Box>
                  </Box>
                );
              })}
          </Box>
          {fileRejectionItems}
        </Box>
      </Box>
    );
  };

  return (
    <>
      {inputType === "patientDocs" && patientDocument()}
      {inputType === "patientImage" && patientImage()}
      {inputType === "referralDocs" && ReferralDocument()}
      {inputType === "userProfile" && userProfile()}
    </>
  );
};

export default FileUpload;
