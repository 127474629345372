import { Reducer } from "redux";

import { get404InitialData, mergeObjects } from "../utils";
import {
  ServiceProviderDetailsRequest,
  ServiceProviderDetailsResponse,
} from "../models/Api/Authorization";
import { ServiceProviderDetailsDispatchTypes } from "../models/DispatchTypes/Authorization";
import { ServiceProviderDetailsActionDispatchTypes } from "../constants/Authorization";

export const initialState: ServiceProviderDetailsResponse = {
  loading: false,
  response: {
    referralId: -1,
    referralServiceProviderId: -1,
    serviceProviderId: "",
    extension: "",
    phone: "",
    fax: "",
    streetName1: null,
    streetName2: null,
    providerCity: null,
    providerCounty: null,
    providerState: null,
    zipCode: null,
    providerAddress: "",
    parentCompany: 0,
    ahcccsId: "",
    medicareId: "",
    contractEffectiveFrom: "",
    contractEffectiveTo: "",
    userCreated: null,
    providerName: "",
    providerNpi: "",
    taxId: "",
    symplrId: 0,
    serviceProviderUid: "",
    country: "",
    email: "",
    plexisOfficeId: 0,
    providerId: 0,
    reimbursementTypeId: null,
    reimbursementTypeName: null,
  },
};

export const initialPostState: ServiceProviderDetailsRequest = {
  loading: false,
  response: {
    providerId: 0,
  },
};
const ServiceProviderDetailsReducer: Reducer<
  ServiceProviderDetailsResponse,
  ServiceProviderDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: get404InitialData(action.response, initialState),
        loading: false,
      });
    }
    case ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_POST_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_POST_SUCCESS: {
      return mergeObjects(state, {
        response: action.response,
        loading: false,
      });
    }
    case ServiceProviderDetailsActionDispatchTypes.SERVICE_PROVIDER_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ServiceProviderDetailsReducer;
