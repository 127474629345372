import { Reducer } from "redux";

import { RoleManagementResponse } from "../models/Api/Roles";
import { RoleManagementDispatchTypes } from "../models/DispatchTypes/Roles";
import { RoleManagementActionDispatchTypes } from "../constants/Roles";
import { mergeObjects } from "../utils";

export const initialState: RoleManagementResponse = {
  loading: false,
  response: [
    {
      roleId: -1,
      roleName: "",
      roleDescription: "",
      roleModule: [
        {
          moduleId: -1,
          moduleName: "",
          roleId: -1,
          roleSubModule: [
            {
              moduleId: -1,
              roleId: -1,
              subModuleId: -1,
              subModuleName: "",
              accesses: [""],
            },
          ],
        },
      ],
    },
  ],
};

const RoleManagementListReducer: Reducer<
  RoleManagementResponse,
  RoleManagementDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case RoleManagementActionDispatchTypes.ROLE_MANAGEMENT_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case RoleManagementActionDispatchTypes.ROLE_MANAGEMENT_SUCCESS: {
      return mergeObjects(state, { response: action.response, loading: false });
    }
    case RoleManagementActionDispatchTypes.DELETE_ROLE_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case RoleManagementActionDispatchTypes.DELETE_ROLE_SUCCESS: {
      return mergeObjects(state, { loading: true });
    }
  }
  return state;
};

export default RoleManagementListReducer;
