import { requiredFieldMsg } from "../../constants/Constants";
import {
  alphabetCountyRegex,
  alphabetOnlyRegex,
  alphabetRegex,
  alphaNumericRegex,
  anyCharacters,
  contractIdPbpRegex,
  emailRegex,
  mbiRegex,
  numAlphabetOnlyRegex,
  numberOnlyRegex,
  numberRegex,
  personNameRegex,
  phoneFaxFormat,
  physicianNameRegex,
  validNonZeroNumber,
} from "../../constants/Regex";

export const rules = {
  firstName: {
    required: { value: true, message: `First name is required.` },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid first name.",
    },
  },
  lastName: {
    required: { value: true, message: `Last name is required.` },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid last name.",
    },
  },
  serviceProviderName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid Service Provider name.",
    },
  },
  zipCode: {
    pattern: {
      value: numberOnlyRegex,
      message: "Please enter valid ZipCode name.",
    },
  },
  email: {
    required: { value: true, message: `Email id is required.` },
    pattern: { value: emailRegex, message: "Please enter valid email id." },
  },
  phone: {
    required: { value: true, message: `Phone no. is required.` },
    maxLength: { value: 14, message: "Max length should be 14 digit." },
    minLength: {
      value: 14,
      message: "Min length should be 10 digit.",
    },
    pattern: {
      value: phoneFaxFormat,
      message: "Please enter valid phone number.",
    },
  },
  mobilePhone: {
    maxLength: { value: 14, message: "Max length should be 14 digit." },
    minLength: {
      value: 14,
      message: "Min length should be 10 digit.",
    },
    pattern: {
      value: phoneFaxFormat,
      message: "Please enter valid phone number.",
    },
  },
  phoneNo: {
    minLength: {
      value: 14,
      message: "Min length should be 10 digit.",
    },
  },
  fax: {
    maxLength: { value: 14, message: "Max length should be 14 digit." },
    minLength: { value: 14, message: "Min length should be 10 digit." },
    pattern: {
      value: phoneFaxFormat,
      message: "Please enter valid fax number.",
    },
  },
  sendFax: {
    required: { value: true, message: `Fax is required.` },
    maxLength: { value: 14, message: "Max length should be 14 digit." },
    minLength: { value: 14, message: "Min length should be 10 digit." },
    pattern: {
      value: phoneFaxFormat,
      message: "Please enter valid fax number.",
    },
  },
  extension: {
    required: { value: true, message: `Ext. is required.` },
  },
  middleName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid middle name.",
    },
  },
  gender: {
    required: { value: true, message: "Gender is required." },
  },
  dateOfBirth: {
    required: { value: true, message: "Date of birth is required." },
  },
  dateOfBirthEligibility: {
    required: { value: true, message: "Required for validation" },
  },
  dateOfBirthOptional: {
    required: { value: true },
  },
  streetName: {
    required: { value: true, message: "Street address is required." },
  },
  referralReceivedDate: {
    required: { value: true, message: "Date  is required." },
  },
  referralReceivedTime: {
    required: { value: true, message: "Time is required." },
  },
  referralSourceDischargeDate: {
    required: { value: true, message: "Date is required." },
  },
  textOnly: {
    required: { value: true },
    pattern: { value: alphabetRegex, message: "Please enter valid text." },
  },
  patientFirstName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid first name.",
    },
  },
  patientLastName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid last name.",
    },
  },
  onlyText: {
    pattern: { value: alphabetOnlyRegex, message: "Please enter valid text." },
  },
  numberOnly: {
    pattern: { value: numberRegex, message: "Please enter valid value." },
  },
  numberAlphabetOnly: {
    pattern: {
      value: numAlphabetOnlyRegex,
      message: "Please enter valid value.",
    },
  },
  urgencyStatus: {
    required: { value: true, message: "Urgency status is required." },
  },

  authType: {
    required: { value: true, message: "Authorization type is required." },
  },

  TemplateCategory: {
    required: { value: true, message: "Category is required." },
  },

  TemplateName: {
    required: { value: true, message: "Template name is required." },
    pattern: {
      value: alphaNumericRegex,
      message: "Please enter valid template name.",
    },
  },
  ContractIdPBP: {
    pattern: {
      value: contractIdPbpRegex,
      message: "Please enter valid Contract ID-PBP.",
    },
    maxLength: { value: 25, message: "25 characters are allowed." },
  },
  type: {
    required: { value: true, message: "Type is required." },
    pattern: { value: validNonZeroNumber, message: "Type is required." },
  },
  requestedBy: {
    required: { value: true, message: "Requested By is required." },
    pattern: { value: validNonZeroNumber, message: "Type is required." },
  },
  serviceStatus: {
    required: { value: true, message: "Service status is required." },
  },
  serviceRequesterName: {
    required: {
      value: true,
      message: "Withdrawal requestor name is required.",
    },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid withdrawal requestor name.",
    },
  },
  serviceStatusReason: {
    required: { value: true, message: "Service status reason is required." },
  },
  requestorFacilityTypeId: {
    required: { value: true, message: `Requestor facility type is required.` },
  },
  referralSourceFacilityTypeId: {
    required: {
      value: true,
      message: `Referral source facility type is required.`,
    },
  },
  emergencyContactName: {
    required: { value: true, message: requiredFieldMsg.EMERGENCY_CONTACT_NAME },
    maxLength: { value: 30, message: "30 characters are allowed." },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid contact name.",
    },
  },
  relationshipName: {
    required: { value: true, message: `Relationship name is required.` },
  },
  relation: {
    required: { value: true, message: `Relationship is required.` },
  },
  requestorName: {
    required: { value: true, message: `Requestor name is required.` },
    maxLength: { value: 100, message: "100 characters are allowed." },
  },
  referralSourceName: {
    maxLength: { value: 30, message: "30 characters are allowed." },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid referral source name.",
    },
  },
  requestorFacilityName: {
    maxLength: { value: 100, message: "100 characters are allowed." },
  },
  requestorFacilityAddress: {
    maxLength: { value: 256, message: "256 characters are allowed." },
  },
  referralSourceFacilityAddress: {
    maxLength: { value: 256, message: "256 characters are allowed." },
  },
  receivedMethod: {
    required: { value: true, message: `Received method is required.` },
  },
  referralManagement: {
    required: {
      value: true,
      message: `Please select a Referral Management System.`,
    },
  },
  authStatus: {
    required: { value: true, message: "Authorization status is required." },
  },
  priorAuthReferralSource: {
    required: { value: true, message: "Referral Source  is required." },
  },
  staffingStatus: {
    required: { value: true, message: "Staffing status is required." },
  },
  createdBy: {
    required: { value: true, message: "Created By  is required." },
    maxLength: { value: 255, message: "255 characters are allowed." },
  },
  FaxTo: {
    required: { value: true, message: "To is required." },
  },
  FaxFrom: {
    required: { value: true, message: "From is required." },
  },
  FaxAdditionalInfo: {
    required: { value: true, message: "Additional information is required." },
  },
  hcpcs: {
    required: { value: true, message: "HCPCS is required." },
    maxLength: { value: 128, message: "128 characters are allowed." },
  },
  requestor: {
    required: { value: true, message: "Requestor  is required." },
  },
  physicianName: {
    required: { value: true, message: "Physician name  is required." },
    pattern: { value: physicianNameRegex, message: `Please enter valid name.` },
  },
  physicianNpi: {
    required: { value: true, message: "NPI is required" },
    minLength: { value: 10, message: "Min length should be 10 digit." },
    pattern: { value: numberOnlyRegex, message: `Please enter valid NPI.` },
  },
  requestorNpi: {
    minLength: { value: 10, message: "Min length should be 10 digit." },
    pattern: { value: numberOnlyRegex, message: `Please enter valid NPI.` },
  },
  physicianTaxId: {
    pattern: { value: numberOnlyRegex, message: `Please enter valid tax id.` },
  },
  masterPhysicianName: {
    required: {
      value: true,
      message: "Please search physician or add manully.",
    },
  },
  emailAddress: {
    pattern: {
      value: emailRegex,
      message: "Please enter valid email address.",
    },
  },
  npi: {
    minLength: { value: 10, message: "Min length should be 10 digit." },
    pattern: { value: numberOnlyRegex, message: `Please enter valid NPI.` },
  },
  checkEligibility: {
    required: { value: true, message: "Required for validation" },
    pattern: {
      value: numAlphabetOnlyRegex,
      message: `Please enter valid value.`,
    },
  },
  checkEligibilityLastName: {
    required: { value: true, message: "Required for validation" },
    pattern: {
      value: personNameRegex,
      message: `Please enter valid text.`,
    },
  },
  checkEligibilitySubscriberId: {
    required: { value: true, message: "Required for validation" },
    pattern: {
      value: numAlphabetOnlyRegex,
      message: `Please enter valid value.`,
    },
    maxLength: { value: 15, message: "15 characters or digits is allowed" },
  },
  checkEligibilityHealthPlan: {
    required: { value: true, message: "Required for validation" },
    patter: { value: anyCharacters, message: "Please select valid plan." },
  },
  healthPlanDropdown: {
    pattern: { value: anyCharacters, message: "Please select valid plan." },
  },
  subscriberId: {
    pattern: {
      value: numAlphabetOnlyRegex,
      message: "Please enter valid subscriber ID.",
    },
    maxLength: { value: 15, message: "15 characters or digits is allowed" },
  },
  streetName1: {
    required: { value: true, message: "Street name 1 is required." },
  },
  streetName2: {
    maxLength: { value: 100, message: "100 characters or digits is allowed" },
  },
  county: {
    maxLength: { value: 100, message: "100 characters are allowed" },
    pattern: {
      value: alphabetCountyRegex,
      message: `Please enter valid county.`,
    },
  },
  physicianCounty: {
    required: { value: true, message: "County is required." },
    maxLength: { value: 100, message: "100 characters are allowed" },
    pattern: {
      value: alphabetCountyRegex,
      message: `Please enter valid county.`,
    },
  },
  city: {
    maxLength: { value: 100, message: "100 characters are allowed" },
    pattern: { value: alphabetRegex, message: `Please enter valid city.` },
  },
  state: {
    maxLength: { value: 100, message: "100 characters are allowed." },
    pattern: { value: alphabetRegex, message: `Please enter valid state.` },
  },
  zipcode: {
    maxLength: { value: 10, message: "Max length should be 10 digit." },
    pattern: {
      value: numberOnlyRegex,
      message: `Please enter valid zipcode.`,
    },
  },
  physicianZipcode: {
    required: { value: true, message: "Zipcode  is required." },
    maxLength: { value: 10, message: "Max length should be 10 digit." },
    pattern: {
      value: numberOnlyRegex,
      message: `Please enter valid zipcode.`,
    },
  },
  roleName: {
    required: { value: true, message: "Role name  is required." },
    pattern: { value: alphabetRegex, message: `Please enter valid name.` },
  },
  roleDescription: {
    required: { value: true, message: "Role description  is required." },
  },
  maxLengthDescription: {
    maxLength: { value: 200, message: "Max length should be 200 characters." },
  },
  dateTimeRequired: {
    required: { value: true, message: "Date is required." },
  },
  timeRequired: {
    required: { value: true, message: "Time is required." },
  },
  documentSource: {
    required: { value: true, message: "Document source is required." },
  },
  documentType: {
    required: { value: true, message: "Document type is required." },
  },
  physicianNameMaster: {
    maxLength: { value: 128, message: "128 characters are allowed" },
    required: {
      value: true,
      message: "Please search physician or add manually.",
    },
  },
  physicianDetails: {
    maxLength: { value: 128, message: "128 characters are allowed" },
    required: {
      value: false,
      message: "Please search physician or add manually.",
    },
  },
  icd10Code: {
    required: {
      value: true,
      message: "ICD code is required",
    },
    maxLength: { value: 75, message: "75 characters are allowed" },
  },
  icd10CodeType: {
    required: {
      value: true,
      message: "ICD code type is required",
    },
  },
  requestedVisit: {
    required: { value: true, message: "Incorrect visits" },
  },
  approvedVisit: {
    required: { value: true, message: "Required" },
  },
  deniedVisit: {
    required: { value: true, message: "Required" },
  },
  fileSelect: {
    required: { value: true, message: "Please select file" },
  },
  validFirstName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid first name",
    },
  },
  validLastName: {
    pattern: {
      value: personNameRegex,
      message: "Please enter valid last name",
    },
  },
  discipline: {
    required: { value: true, message: `Discipline is required` },
  },
  receivedMethodId: {
    required: { value: true, message: `Received method is required` },
  },
  addedDocuments: {
    required: { value: true, message: "Please add document." },
  },
  icdCodes: {
    required: { value: true, message: "Please add ICD codes" },
    minLength: 1,
  },
  visits: {
    required: { value: true, message: "Incorrect visit" },
  },
  authRequestorName: {
    maxLength: { value: 30, message: "30 characters are allowed." },
    pattern: {
      value: personNameRegex,
      message: "Please enter valid requestor name.",
    },
  },
  phoneNumber: {
    maxLength: { value: 14, message: "Max length should be 14 digit." },
    minLength: {
      value: 14,
      message: "Min length should be 10 digit.",
    },
    pattern: {
      value: phoneFaxFormat,
      message: "Please enter valid phone number.",
    },
  },
  patientDetails: {
    message: "SubscriberId is not available for selected patient.",
  },
  healthPlan: {
    required: { value: true, message: "Health plan is required." },
  },
  benefitPlan: {
    required: { value: true, message: "Benefit plan is required." },
  },
  product: {
    required: { value: true, message: "Product is required." },
  },
  healthPlanState: {
    required: { value: true, message: "State is required." },
  },
  infoSubscriberId: {
    required: { value: true, message: "Subscriber ID is required." },
    pattern: {
      value: numAlphabetOnlyRegex,
      message: "Please enter valid subscriber ID.",
    },
    maxLength: { value: 15, message: "15 characters or digits are allowed" },
  },
  status: {
    required: { value: true, message: "Status is required." },
  },
  streetName1Required: {
    required: { value: true, message: "Street name 1 is required." },
    maxLength: { value: 100, message: "100 characters or digits are allowed" },
  },
  cityRequired: {
    required: { value: true, message: "City is required." },
    maxLength: { value: 100, message: "100 characters are allowed" },
    pattern: { value: alphabetRegex, message: `Please enter valid city.` },
  },
  stateRequired: {
    required: { value: true, message: "State is required." },
    maxLength: { value: 100, message: "100 characters are allowed." },
    pattern: { value: alphabetRegex, message: `Please enter valid state.` },
  },
  zipCodeRequired: {
    required: { value: true, message: "Zip code is required." },
    maxLength: { value: 10, message: "Max length should be 10 digit." },
    pattern: {
      value: numberOnlyRegex,
      message: `Please enter valid zip code.`,
    },
  },
  mbiNumber: {
    pattern: {
      value: mbiRegex,
      message: "Invalid MBI Number",
    },
    maxLength: { value: 11, message: "11 characters or digits are allowed." },
  },
  ahccsNumber: {
    pattern: {
      value: numAlphabetOnlyRegex,
      message: "Please enter valid ahccs number.",
    },
    maxLength: { value: 50, message: "50 characters or digits are allowed." },
  },
  maxLengthAgencyNoteDescription: {
    maxLength: {
      value: 1500,
      message: "Max length should be 1500 characters.",
    },
  },
  ntuReason: {
    required: { value: true, message: "NTU Reason is required." },
  },
  authorizationNumber: {
    pattern: {
      value: numAlphabetOnlyRegex,
      message: `Please enter valid authorization no.`,
    },
    maxLength: { value: 20, message: "20 characters or digits are allowed." },
  },
  noticeToReferral: {
    required: { value: true, message: "Notice to Referral is required" },
  },
  homeHealthServices: {
    required: { value: true, message: "Home Health Services is required" },
  },
  faxOtherReason: {
    required: { value: true, message: "Reason is required" },
  },
  serviceRequestNumber: {
    required: { value: true, message: "Service Request Number is required." },
  },
  letterCopyType: {
    required: { value: true, message: "Type is required." },
  },
};
