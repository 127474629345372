import { combineReducers } from "redux";

import { ApplicationState } from "./ApplicationState";
import {
  AdminNotesList,
  AllPatientIntakeTabList,
  AllPatientRecordList,
  AppConfigsReducer,
  AssignStaffIncompleteRequest,
  AuthFollowingPhysicianDetails,
  AuthOrderingPhysicianDetails,
  AuthorizationDetailTabs,
  AuthorizationHistory,
  AuthorizationStatus,
  AuthPastMedicalHistory,
  CareCordinationProgram,
  CareGiver,
  CheckEligibility,
  ClinicalGroupingReducer,
  DeactivateNotesTemplate,
  Delete,
  DeleteAdminNotesTemplate,
  DischargeReason,
  Discipline,
  DisciplineRequestorReducer,
  DisciplineTemplate,
  DisciplineType,
  DiseaseType,
  DocumentCreatedBy,
  DocumentDownload,
  DocumentReview,
  DocumentSource,
  DocumentType,
  EligibilityHistoryReducer,
  EligibilityRecord,
  ExcelDownload,
  FollowingPhysicianDetails,
  GeneralInfo,
  HealthProgram,
  Icd10Records,
  IcdCodeType,
  IncompleteRequestPatient,
  InformationReceivedResponse,
  LoggedInUser,
  MasterAccess,
  MyPatientList,
  NonCoveredServices,
  OrderAcceptor,
  OrderingSource,
  PatientInformationResponse,
  PatientInformationView,
  PatientInforReceived,
  PatientPhotoUpdate,
  PatientRecordDetail,
  PatientReferralInfoResponse,
  PatientReferralRequest,
  PatientSelectedAuthStatus,
  PatientStateReducer,
  PostServiceProvider,
  PostServiceRequest,
  PutAuthorizationDetailTab,
  PutMyPatientsList,
  ReauthCompleteReducer,
  ReauthDocumentReview,
  ReAuthPatientDetails,
  ReauthReferralIntakeRequest,
  ReauthReferralIntakeSubmit,
  ReAuthReview,
  ReAuthServiceRequest,
  ReAuthTimeline,
  ReceivedMethodResponse,
  ReferralDiscipline,
  ReferralServiceStatus,
  PriorAuthReferralSource,
  ReAuthReferralSource,
  ReferralSubmit,
  Relationship,
  RemovePatient,
  ResumeIntakeReducer,
  RoleDetails,
  RoleManagementList,
  RoleReducer,
  SaveIcd10Records,
  SaveOrUpdateDocumentFile,
  SavePatientInformation,
  SaveReferralDocument,
  SaveUpdatePatientRequest,
  ServiceCareType,
  ServiceDisciplines,
  ServicePastMedicalHistory,
  ServiceProvider,
  ServiceProviderData,
  ServiceProviderDetails,
  ServicePsychologicalHistory,
  ServiceRequest,
  ServiceStatusReason,
  StaffingStatus,
  UpdateDiscipline,
  UpdateRoleDetailsReducer,
  UrgencyStatus,
  UserDetails,
  UserList,
  UserModule,
  MasterNotesCategory,
  SaveNotes,
  UpdateNotes,
  GetNotesById,
  MasterNotesApprovalStatus,
  StaffingList,
  StaffingAssignStaff,
  StaffingUnassignStaff,
  DisciplineNoteReducer,
  DisciplineTemplateNotesReducer,
  GetDisciplineLevelNotesReducer,
  SaveDisciplineLevelNotesReducer,
  UpdateDisciplineLevelNotesReducer,
  ReferralNoteList,
  GetReferralLevelNotesReducer,
  SaveReferralLevelNotesReducer,
  UpdateReferralLevelNotesReducer,
  NotesTemplateJsonReducer,
  UserPermissionReducer,
  RemoveStaffingPatientReducer,
  RouteUrlInfoReducer,
  PermissionReducer,
  HealthPlanReducer,
  InitiateFaxSend,
  GenerateAuthTemplateReducer,
  BenefitPlanDetailsReducer,
  StoreFilterPayload,
  ServiceStatusReasonReducer,
  CountyReducer,
  UserListWithFilterReducer,
  MasterNtuReasonReducer,
  LanguageMasterReducer,
  AccomodationMasterReducer,
  UpdatePatientHeaderReducer,
  NotifyInternalDataValuesReducer,
  AgencyCommunicationReducer,
  AgencyCommunicationNoteReducer,
  LevelOfFunctionReducer,
  EligibilityDataReducer,
  DeleteServiceProviderReducer,
  AuthorizationTypeMasterReducer,
  FaxFormMasterReducer,
  FaxFormOptionMasterReducer,
  AllPendingUnassignStaff,
  ServiceRequestNumberMasterReducer,
  LetterCopyTypeMasterReducer,
  SaveAttachLetter,
  NppesDetailsDataReducer,
  ReferralManagementSystem,
} from "../reducers";

import PayerReducer from "../reducers/PayerList";
import PhysicianRecordsReducer from "../reducers/PhysicianRecords";
import ReferralIntakePhysicianDetailsReducer from "../reducers/ReferralIntakePhysicianDetails";
import ReviewReducer from "../reducers/ReviewRequestList";
import ServiceProviderMasterReducer from "../reducers/ServiceProviderMaster";
import Survey from "../reducers/DisciplineSurvey";

const createRootReducer = combineReducers<ApplicationState>({
  allPatientIntakeTabState: AllPatientIntakeTabList,
  allPatientRecordState: AllPatientRecordList,
  appConfigsState: AppConfigsReducer,
  assignStaffIncompleteRequestState: AssignStaffIncompleteRequest,
  authFollowingPhysicianDetailsState: AuthFollowingPhysicianDetails,
  authOrderingPhysicianDetailsState: AuthOrderingPhysicianDetails,
  authorizationDetailTabsState: AuthorizationDetailTabs,
  authorizationhistoryState: AuthorizationHistory,
  authorizationStatusState: AuthorizationStatus,
  authPastMedicalHistoryState: AuthPastMedicalHistory,
  careCoordinationProgramState: CareCordinationProgram,
  careGiverState: CareGiver,
  careTypeState: ServiceCareType,
  checkEligibilityState: CheckEligibility,
  clinicalGroupState: ClinicalGroupingReducer,
  disaseTypeState: DiseaseType,
  dischargeReasonState: DischargeReason,
  disciplineRequestorState: DisciplineRequestorReducer,
  disciplinesState: ServiceDisciplines,
  disciplineState: Discipline,
  disciplineTemplateState: DisciplineTemplate,
  disciplineTypeState: DisciplineType,
  documentCreatedState: DocumentCreatedBy,
  documentDownloadState: DocumentDownload,
  documentReviewState: DocumentReview,
  documentSourceState: DocumentSource,
  documentTypeState: DocumentType,
  eligibilityHistoryState: EligibilityHistoryReducer,
  eligibilityRecordState: EligibilityRecord,
  excelDownloadState: ExcelDownload,
  followingPhysicianDetailsState: FollowingPhysicianDetails,
  generalInfoState: GeneralInfo,
  healthProgramState: HealthProgram,
  icd10RecordState: Icd10Records,
  icdCodeTypeState: IcdCodeType,
  incompleteRequestState: IncompleteRequestPatient,
  informationReceivedState: InformationReceivedResponse,
  isDeleted: Delete,
  loggedInUserState: LoggedInUser,
  masterAccessState: MasterAccess,
  myPatientListState: MyPatientList,
  nonCoveredServiceState: NonCoveredServices,
  orderAcceptorState: OrderAcceptor,
  orderingSourceState: OrderingSource,
  pastMedicalHistoryState: ServicePastMedicalHistory,
  patientInformationReceivedState: PatientInforReceived,
  patientInformationState: PatientInformationResponse,
  patientInformationViewState: PatientInformationView,
  patientPhotoUpdateState: PatientPhotoUpdate,
  patientRecordDetailState: PatientRecordDetail,
  patientReferralInfoState: PatientReferralInfoResponse,
  patientReferralRequestState: PatientReferralRequest,
  patientSelectedAuthStatusState: PatientSelectedAuthStatus,
  patientServiceProviderMasterState: ServiceProviderMasterReducer,
  patientStateState: PatientStateReducer,
  payerState: PayerReducer,
  physicianRecordsState: PhysicianRecordsReducer,
  postServiceProviderState: PostServiceProvider,
  postServiceRequestState: PostServiceRequest,
  psychologicalHistoryState: ServicePsychologicalHistory,
  putAuthorizationDetailTabState: PutAuthorizationDetailTab,
  putMyPatientListState: PutMyPatientsList,
  ReauthCompleteState: ReauthCompleteReducer,
  reAuthDocumentReviewState: ReauthDocumentReview,
  reauthPatientDetailsState: ReAuthPatientDetails,
  reauthReferralIntakeRequestState: ReauthReferralIntakeRequest,
  reAuthReviewState: ReAuthReview,
  reAuthServiceRequestState: ReAuthServiceRequest,
  reAuthSubmitState: ReauthReferralIntakeSubmit,
  reAuthTimelineState: ReAuthTimeline,
  receivedMethodState: ReceivedMethodResponse,
  referralDisciplineState: ReferralDiscipline,
  referralDocumentState: SaveReferralDocument,
  referralIntakePhysicianDetailsState: ReferralIntakePhysicianDetailsReducer,
  referralServiceStatusState: ReferralServiceStatus,
  priorAuthReferralSourceState: PriorAuthReferralSource,
  reAuthReferralSourceState: ReAuthReferralSource,
  referralSubmitState: ReferralSubmit,
  relationshipState: Relationship,
  RemovePatientState: RemovePatient,
  ResumeIntakeState: ResumeIntakeReducer,
  reviewState: ReviewReducer,
  roleDetailsState: RoleDetails,
  roleManagementListState: RoleManagementList,
  RoleState: RoleReducer,
  saveDocumentFileState: SaveOrUpdateDocumentFile,
  saveIcd10RecordState: SaveIcd10Records,
  SavePatientInformationState: SavePatientInformation,
  saveUpdatePatientRequestState: SaveUpdatePatientRequest,
  serviceProviderDataState: ServiceProviderData,
  serviceProviderDetailsState: ServiceProviderDetails,
  serviceProviderState: ServiceProvider,
  serviceRequestState: ServiceRequest,
  serviceStatusReasonState: ServiceStatusReason,
  staffingStatusState: StaffingStatus,
  updateDisciplineState: UpdateDiscipline,
  updateRoleDetailsState: UpdateRoleDetailsReducer,
  urgencyStatusState: UrgencyStatus,
  userDetailsState: UserDetails,
  userListState: UserList,
  userModuleState: UserModule,
  serveyDetailsUpdate: Survey,
  adminNotesListState: AdminNotesList,
  notesApprovalStatusState: MasterNotesApprovalStatus,
  deactivateNotesTemplateState: DeactivateNotesTemplate,
  deleteAdminNotesTemplateState: DeleteAdminNotesTemplate,
  adminCategoryNotesMasterState: MasterNotesCategory,
  saveAdminNotesState: SaveNotes,
  GetadminNotesState: GetNotesById,
  updateAdminNotesState: UpdateNotes,
  staffingListState: StaffingList,
  staffingAssignStaffState: StaffingAssignStaff,
  staffingUnassignStaffState: StaffingUnassignStaff,
  disciplineNoteListState: DisciplineNoteReducer,
  disciplineTemplateNotesState: DisciplineTemplateNotesReducer,
  GetDisciplineLevelNotesState: GetDisciplineLevelNotesReducer,
  SaveDisciplineLevelNotesState: SaveDisciplineLevelNotesReducer,
  UpdateDisciplineLevelNotesState: UpdateDisciplineLevelNotesReducer,
  referralNotesListState: ReferralNoteList,
  GetReferralLevelNotesState: GetReferralLevelNotesReducer,
  SaveReferralLevelNotesState: SaveReferralLevelNotesReducer,
  UpdateReferralLevelNotesState: UpdateReferralLevelNotesReducer,
  NotesTemplateJsonState: NotesTemplateJsonReducer,
  UserPermissionState: UserPermissionReducer,
  RemoveStaffingPatientState: RemoveStaffingPatientReducer,
  RouteUrlInfoState: RouteUrlInfoReducer,
  permissionState: PermissionReducer,
  healthPlanMasterState: HealthPlanReducer,
  postFaxSendingState: InitiateFaxSend,
  generateAuthTemplateState: GenerateAuthTemplateReducer,
  benefitPlanDetailsState: BenefitPlanDetailsReducer,
  filterState: StoreFilterPayload,
  serviceStatusReasonMasterState: ServiceStatusReasonReducer,
  countyState: CountyReducer,
  userListWithFilterState: UserListWithFilterReducer,
  masterNtuReasonState: MasterNtuReasonReducer,
  languageState: LanguageMasterReducer,
  accomodationState: AccomodationMasterReducer,
  updatePatientHeaderState: UpdatePatientHeaderReducer,
  NotifyInternalDataValuesState: NotifyInternalDataValuesReducer,
  masterAgencyCommunicationState: AgencyCommunicationReducer,
  agencyCommunicationNote: AgencyCommunicationNoteReducer,
  levelOfFunctionState: LevelOfFunctionReducer,
  eligibilityData: EligibilityDataReducer,
  deleteServiceProviderState: DeleteServiceProviderReducer,
  MasterAuthorizationTypeState: AuthorizationTypeMasterReducer,
  masterFaxFormState: FaxFormMasterReducer,
  masterFaxFormOptionState: FaxFormOptionMasterReducer,
  allPendingUnassignStaffState: AllPendingUnassignStaff,
  letterCopyTypeMasterState: LetterCopyTypeMasterReducer,
  serviceRequestNumberMasterState: ServiceRequestNumberMasterReducer,
  saveAttachLetterState: SaveAttachLetter,
  NPPESDataState: NppesDetailsDataReducer,
  referralMgmtSystemState: ReferralManagementSystem,
});

export default createRootReducer;
