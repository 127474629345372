import { Reducer } from "redux";

import { ServiceRequestResponse } from "../models/Api/Service";
import { ServiceRequestDispatchTypes } from "../models/DispatchTypes/Service";
import { ServiceRequestActionDispatchType } from "../constants/Service";

const initialState: ServiceRequestResponse = {
  loading: false,
  response: {
    referralId: 0,
    userCreated: null,
    startOfCareDate: "",
    referralDisciplineDto: [],
    referralCareTypeDto: [],
    diseasesDto: [],
    psychologicalDiseasesDto: [],
    currentEndDate: null,
    currentStartDate: null
  },
};

const ServiceRequestReducer: Reducer<
  ServiceRequestResponse,
  ServiceRequestDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case ServiceRequestActionDispatchType.SERVICE_REQUEST_RESET: {
      return initialState;
    }
  }
  return state;
};

export default ServiceRequestReducer;
