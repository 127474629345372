import { Reducer } from "redux";

import { AuthFollowingPhysicianDetailsResponse } from "../models/Api/PhysicianDetails";
import { AuthFollowingPhysicianDetailsDispatchTypes } from "../models/DispatchTypes/PhysicianDetails";
import {
  AuthFollowingPhysicianDetailsActionDispatchType,
  PhysicianTypeID,
} from "../constants/PhysicianDetails";
import { get404InitialData, mergeObjects } from "../utils";

export const initialState: AuthFollowingPhysicianDetailsResponse = {
  loading: true,
  response: {
    referralId: 0,
    referralPhysicianDetailId: 0,
    physicianId: 0,
    physicianName: "",
    physicianNpiId: "",
    physicianAddress: "",
    phoneNo: "",
    phoneExt: "",
    taxId: "",
    fax: "",
    streetName1: "",
    streetName2: "",
    city: "",
    physicianState: "",
    zipCode: "",
    userCreated: null,
    userUpdated: "",
    physicianTypeId: PhysicianTypeID.DEFAULT,
  },
};

const AuthFollowingPhysicianDetailsReducer: Reducer<
  AuthFollowingPhysicianDetailsResponse,
  AuthFollowingPhysicianDetailsDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case AuthFollowingPhysicianDetailsActionDispatchType.GET_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case AuthFollowingPhysicianDetailsActionDispatchType.GET_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, {
        response: get404InitialData(action.response, initialState),
        loading: false,
      });
    }
    case AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_INVOKE: {
      return mergeObjects(state, { loading: true });
    }
    case AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_SUCCESS: {
      return mergeObjects(state, { response: action.response, loading: false });
    }

    case AuthFollowingPhysicianDetailsActionDispatchType.PUT_FOLLOWING_PHYSICIAN_DETAILS_ERROR: {
      return mergeObjects(state, { error: action.response, loading: false });
    }
    case AuthFollowingPhysicianDetailsActionDispatchType.GET_FOLLOWING_PHYSICIAN_DETAILS_RESET: {
      return initialState;
    }
  }
  return state;
};

export default AuthFollowingPhysicianDetailsReducer;
