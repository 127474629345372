import { Reducer } from "redux";

import { InitiateFaxSendingResponse } from "../models/Api/Authorization";
import { InitiateFaxDispatchTypes } from "../models/DispatchTypes/Authorization";
import { PostInitiateFaxActionDispatchTypes } from "../constants/Authorization";

export const initialState: InitiateFaxSendingResponse = {
  loading: false,
  response: {
    referralBlobId: -1,
    referralId: -1,
    blobIdList: [],
    faxNumber: "",
    receivedDateTime: "",
    notes: "",
    sendTo: "",
    sentFrom: "",
    faxFile: [],
    faxFormId: -1,
    physicianDateTime: "",
  },
};

const InitiateFaxSendingReducer: Reducer<
  InitiateFaxSendingResponse,
  InitiateFaxDispatchTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_INVOKE: {
      return {
        ...state,
        loading: true,
      };
    }
    case PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_SUCCESS: {
      return {
        ...state,
        response: action.response,
        loading: false,
      };
    }
    case PostInitiateFaxActionDispatchTypes.POST_INITIATE_FAX_RESET: {
      return initialState;
    }
  }
  return state;
};

export default InitiateFaxSendingReducer;
