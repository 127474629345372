import React from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";

import { displayFlex } from "../../styles/mui/styles/display";
import { FormInputProps } from "./FormInputProps";
import { GenderInputUI } from "./styles/style";
import { isNil } from "lodash";
import colors from "../../styles/colors/colors";
import fontWeight from "../../styles/mui/fontWeight";
import { getValue } from "../../utils";
import useMenuItem from "../../hooks/useMenuItem";
import { DropdownOption } from "./constant/FormComponents";

export const FormInputDropdown: React.FC<FormInputProps> = ({
  control,
  disabled,
  helper,
  label,
  list,
  name,
  onChangeHandler,
  mandatorySymbol,
  readOnly,
  InputStyle,
}) => {
  const { open, onOpen, onClose } = useMenuItem();

  const checkInitialData = (data: any) =>
    Array.isArray(data) && data.length > 0 && data[0].label !== "";

  const generateSingleOptions = (list: DropdownOption[]) => {
    if (!checkInitialData(list)) return [];
    return list.map((option) => (
      <MenuItem
        key={`${option.value}-${option.label}`}
        value={option.value}
        disabled={option.disabled || false}
      >
        {option.label}
      </MenuItem>
    ));
  };
  if (!list) return null;

  return (
    <FormControl variant="standard" size={"small"} sx={GenderInputUI}>
      {label && (
        <InputLabel
          required={
            helper && helper.required && helper.required.value
              ? helper.required.value
              : false
          }
          shrink={true}
          sx={displayFlex}
        >
          <Box sx={displayFlex}>
            <Typography
              variant="h5"
              fontWeight={fontWeight.Weight[3]}
              color={colors.fonts[2]}
              lineHeight={"0.95rem"}
            >
              {label}
            </Typography>
            {!getValue(helper, "required.value", "") && mandatorySymbol && (
              <Typography
                variant="h3"
                fontWeight={fontWeight.Weight[3]}
                color={colors.red[100]}
                pl={"0.4rem"}
              >
                *
              </Typography>
            )}
          </Box>
        </InputLabel>
      )}
      <Controller
        defaultValue={""}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => {
          return (
            <>
              <Select
                required={helper && helper.required && helper.required.value}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  disableScrollLock: true,
                }}
                error={error !== undefined}
                labelId="common-form-input-dropdown"
                id="common-form-input-dropdown"
                onChange={(e) => {
                  onChangeHandler && onChangeHandler(e);
                  onChange(e);
                }}
                value={!isNil(value) && value !== 0 ? value : ""}
                sx={InputStyle}
                onInput={onChange}
                inputRef = {ref}
                fullWidth
                disabled={disabled}
                readOnly={readOnly}
                displayEmpty
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                data-testId={name}
              >
                <MenuItem disabled value={""}>
                  Choose
                </MenuItem>
                {generateSingleOptions(list)}
              </Select>
              {helper && helper.required && helper.required.value && (
                <FormHelperText>{error ? error.message : null}</FormHelperText>
              )}
            </>
          );
        }}
        control={control}
        name={name}
        rules={helper}
      />
    </FormControl>
  );
};
